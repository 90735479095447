import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { commonActions } from '../../_actions';
import ObjectInfoLine from '../../_components/ObjectInfoLine';
import { ChoiceList } from './ChoiceList';
import { EditObject } from './EditObject';

import { Objects, ObjectsMap } from '../../_config';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem',
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
});

class ItemEditPage extends React.Component {

    constructor(props) {
        super(props)
        this.scope = this.props.scope;
        this.model = this.props.model;
        this.itemId = this.props.embedded ? this.props.itemId : this.props.match.params.itemId;
        this.creation = !this.itemId
        this.fields = this.creation ? this.model.creation.fields : this.model.edition.fields;
    }

    componentDidMount() {
        if (this.creation) {
            this.props.dispatch(commonActions.create(this.scope, this.model, this.props.data.parent));
        } else {
            this.props.dispatch(commonActions.edit(this.scope, this.itemId, this.model));
        }
        /*this.fields.filter( field => (field.type === 'choice' || field.type === 'choicelist') && field.sourcetype === 'object').forEach( field => {
            this.props.dispatch(commonActions.getAll(this.scope, ObjectsMap[field.source], field.name));
        });*/
    }

    getData() {
        let { data } = this.props;

        if (data && this.scope) {
            const scopedData = data[this.scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }

    getItem() {
        const data = this.getData();
        return data.item;
    }



/*    getSource(fieldName) {
        const data = this.getData();

        const source = data && data.sources && data.sources[fieldName];

        return source;
    }

    handleChange = (prop, path, source) => event => {

        const object = {};
        const inputValue = event.target.value;
        if (source) {
            object[prop] = source.find( element => element.id === inputValue);
        } else {  
            object[prop] = inputValue;
        }

        this.props.dispatch(commonActions.updateCurrentEdition(this.scope, object, path, this.model, this.props.handleChange));
    };

    handleFileChange = (prop, path) => event => {
        console.log(event.target.files);
        const object = {};
        object[prop] = event.target.files[0];
        this.props.dispatch(commonActions.updateCurrentEdition(this.scope, object, path, this.model));
    };

    handleSelect = (prop, path, selectedItems) => {
        const object = {};
        
        object[prop] = selectedItems;

        this.props.dispatch(commonActions.updateCurrentEdition(this.scope, object, path, this.model));
    }

      

      handleChangeSubObject = (prop, object, obj) => {
        console.log('EditItemPage handle change from sub ', object, obj);
        const newObject = {};
        newObject[prop] = object;
        this.props.dispatch(commonActions.updateCurrentEdition(this.scope, newObject, null, this.model));
      }*/

      handleSubmit = event => {
        const item = this.getItem();
        console.log('handleSubmit ', item);
        this.props.dispatch(commonActions.save(this.scope, this.creation, item, this.model, this.props.urlList));
      }

    handleObjectChange = object => {
        this.props.dispatch(commonActions.updateCurrentEdition(this.scope, object, null, this.model));
    }

     /* makeInputFields = (fields, item, classes, path) => {

        const inputFields = [];
        
        if (item && fields) {
            fields.forEach((element, index) => {

                let inputField;

                const fieldPath = [element.name];
                const fullFieldPath = path ? path.concat(fieldPath) : fieldPath;

                if (element.type === 'structure') {
                    let structValue = item ? item[element.name] : {};
                    structValue = structValue ? structValue : {};
                    inputFields.push(
                        (<div key={"form_struct_" +index}>

                                <Typography>{element.name}</Typography>
                                <EditObject
                                    fields={element.fields}
                                    object={structValue}
                                    handleChange={ object => {
                                        this.handleChangeSubObject(element.name, object, structValue);
                                    }}
                                />
                                <div>
                                   
                                </div>
                            </div>
                        )
                    );

                } else if (element.type === 'choicelist') {

                    let source = this.getSource(element.name);//this.props.data && this.props.data.sources && this.props.data.sources[element.name] ? this.props.data.sources[element.name] : null;
                    const selectedItems = item[element.name] ? item[element.name] : null; //.map( obj => obj.id)
                    
                    inputFields.push(
                        (<div key={"form_choicelist_" +index}>
                        <ChoiceList
                            model={ObjectsMap[this.model.fields[element.name].object]}
                            items={source}
                            selectedItems={selectedItems}
                            handleSelect={selectedItems => {this.handleSelect(element.name, path, selectedItems)}}
                            canCreate={element.canCreate}
                            embedded={element.embedded}
                        />
                        </div>)
                    );

                } else {

                    if (element.type === 'choice') {
                        let options;
                        //let source = this.props.data && this.props.data.sources && this.props.data.sources[element.name] ? this.props.data.sources[element.name] : null;
                        let source = this.getSource(element.name);
                        console.log('source', source);
                        if (element.sourcetype === 'object' && source) {
                            options = (source.map( (value, index) => {
                                return <MenuItem key={'option_' + element.name + '_' + index} value={value.id}>{value.name}</MenuItem>;
                            }));
                        } else {
                            options = (null);
                        }
                        
                        const value = item[element.name] ? item[element.name].id : '';//onChange={this.handleChange(element.name)}
                        inputField = (
                            <Select
                                value={value}
                                onChange={this.handleChange(element.name, path, source)}
                                inputProps={{
                                    name: element.name,
                                    id: element.name + '-choice',
                                }}
                            >
                                <MenuItem value="">
                                <em>None</em>
                                </MenuItem>
                                {options}
                            </Select>
                        );
                    } else if (element.type === 'file') {
                        inputField = (
                            <div>
                                <input type="file" onChange={this.handleFileChange(element.name, path)} />
                            </div>
                        );
                    } else {
                        inputField = (<Input id="component-simple" disabled={element.readonly ? true : false} onChange={this.handleChange(element.name, path)} value={item[element.name]}/>);
                    }
    
                    inputFields.push(
                        (<FormControl key={"form_input_" +index} className={classes.formControl}>
                                <InputLabel htmlFor="component-simple">{element.name}</InputLabel>
                                {inputField}
                            </FormControl>
                        )
                    );

                }

                
            });
        }

        return inputFields;
      }*/

    render() {
        const { classes, embedded } = this.props;

        const item = this.getItem();

        this.state = {item: item}; 
            
        const header = embedded ? (null) : (
            <Typography variant="h6" color="inherit" noWrap>
                {this.creation ? 'Nouveau ' : 'Edition '}{this.model.name}
            </Typography>
        );

        const footer = embedded ? (null) : (
                <div>
                <Divider />
                {this.itemId &&
                    <ObjectInfoLine object={item} />
                }

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center" >
                <Button variant="contained" size="small" className={classes.button} onClick={this.handleSubmit}>
                    <SaveIcon />
                    {this.creation ? 'Créer' : 'Mettre à jour'}
                </Button>
                </Grid>
                </div>
        );

        return (
            <div>
                {header}
                {item &&
                <div>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <EditObject
                            dispatch={this.dispatch}
                            scope={this.scope + "_object"}
                            fields={this.fields}
                            object={item}
                            model={this.props.model}
                            handleChange={ object => {
                                this.handleObjectChange(object);
                            }}
                        />
                    </Grid>
                </Grid>
                {footer}
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    
    return {
        itemId,
        data
    };
}

const connectedItemEditPage = withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ItemEditPage));
export { connectedItemEditPage as ItemEditPage };