import { authHeader, endpoint } from '../_helpers';

import moment from 'moment'

export const commonService = {
    getAll,
    exportAll,
    getOne,
    create,
    save,
    deleteItem,
    logout
};

const config = {
    apiUrl: endpoint
}

const prepareFilterValue = (value) => {
    if (value instanceof Date) {
        return moment(value).format('YYYY-MM-DD');
    } else {
        return value;
    }
}

function getAll(model, params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    var url = new URL(`${config.apiUrl}/${model.name}`);

    if (params) {
        Object.keys(params).forEach(key => {
            let filterValue = params[key];
            if (Array.isArray(filterValue)) {
                filterValue = filterValue.join(',');
            }
            url.searchParams.append(key, filterValue);
        })
    }

    return fetch(url, requestOptions).then(handleResponse);
}

function exportAll(model, params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    var url = new URL(`${endpoint}/${model.name}/export`);
    
    if (params) {
        Object.keys(params).forEach(key => {
            let filterValue = params[key];
            if (filterValue !== undefined) {
                if (Array.isArray(filterValue)) {
                    filterValue = filterValue.map( v => prepareFilterValue(v) ).join(',');
                } else {
                    filterValue = prepareFilterValue(filterValue);
                }
                url.searchParams.append(key, filterValue);
            }
        })
    }

    return fetch(url, requestOptions).then(res => {
        
        let fileName = 'export.csv';

        for(let entry of res.headers.entries()) {
            if (entry[0] === 'content-disposition') {
                fileName = entry[1].replace('attachment; filename=', '');
            }
        }
        res.blob().then(function(blob) {
             
        var a = document.createElement('a');
        a.download = fileName;
        a.rel = 'noopener';
        var binaryData = [];
        binaryData.push(blob);
        a.href = window.URL.createObjectURL(new Blob(binaryData, {type: "text/csv"}))
        setTimeout(function () { 
            try {
                a.dispatchEvent(new MouseEvent('click'))
              } catch (e) {
                var evt = document.createEvent('MouseEvents')
                evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
                                      20, false, false, false, false, 0, null)
                a.dispatchEvent(evt)
              }
         }, 0)
          })
       
    });
}

function getOne(itemId, model) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/${model.name}/${itemId}`, requestOptions).then(handleResponse);
}

function deleteItem(item, model) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/${model.name}/${item.id}`, requestOptions).then(handleResponse);
}

function create(model, parent) {

    let newItem = {};

    if (parent && model.parentField) {
        newItem[model.parentField] = parent;
    }

    newItem = model.createObject ? model.createObject(newItem) : newItem;

    return Promise.resolve(newItem);
}

function save(creation, item, model) {

    let method;
    let url = `${config.apiUrl}/${model.name}`;
    let payload = {};
    let fields;
    if (creation) {
        method = 'POST';
        fields = model.creation.fields;
    } else {
        method = 'PATCH';
        url = `${url}/${item.id}`;
        fields = model.edition.fields;
    }

    fields.forEach(field => {
        const fieldValue = item[field.name];
        payload[field.name] = (fieldValue !== undefined) ? fieldValue : null;
    });

    const requestOptions = {
        method: method,
        headers: {...authHeader(), ...{ 'Content-Type': 'application/json' }},
        body: JSON.stringify(payload),
    };

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(item => {
            // save successful
            return item;
        });
}

function logout(sessionExpired) {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout(true);
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        console.log('handleReponse', data);
        return data;
    });
}