
const checkRights = function(right, user, context, objectModel) {
    
    const rights = getRights(user, context, objectModel);

    return rights ? (rights[right] === true) : false;
}

export const getRights = function(user, context, objectName) {

    let rights;
    
    if (user.user.rights) {
        rights = user.user.rights[objectName];
    }

    return rights ? rights : {};
}

export const canList = function(user, context, objectModel) {
    return checkRights('list', user, context, objectModel);
}

export const canRead = function(user, context, objectModel) {
    return checkRights('read', user, context, objectModel);
}

export const canUpdate = function(user, context, objectModel) {
    return checkRights('update', user, context, objectModel);
}

export const canCreate = function(user, context, objectModel) {
    return checkRights('create', user, context, objectModel);
}

export const canDelete = function(user, context, objectModel) {
    return checkRights('delete', user, context, objectModel);
}

export const canArchive = function(user, context, objectModel) {
    return checkRights('archive', user, context, objectModel);
}