import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../_helpers';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import { commonActions } from '../../_actions';
import ObjectInfoLine from '../../_components/ObjectInfoLine';
import { ReadObject, CustomReadObject } from './ReadObject';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem',
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
});

class ItemReadPage extends React.Component {

    constructor(props) {
        super(props)
        this.scope = this.props.scope;
        this.model = this.props.model;
        this.itemId = this.props.embedded ? this.props.itemId : this.props.match.params.itemId;
        
        this.fields = this.model.view.fields;
    }

    componentDidMount() {
        this.props.dispatch(commonActions.read(this.scope, this.itemId, this.model));
        /*this.fields.filter( field => (field.type === 'choice' || field.type === 'choicelist') && field.sourcetype === 'object').forEach( field => {
            this.props.dispatch(commonActions.getAll(this.scope, ObjectsMap[field.source], field.name));
        });*/
    }

    getData() {
        let { data } = this.props;

        if (data && this.scope) {
            const scopedData = data[this.scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }

    getItem() {
        const data = this.getData();
        return data.item;
    }

    handleEdit = () => {
        console.log(this);
        history.push('/edit/' + this.model.name + '/' + this.itemId);
    }     

    render() {
        const { classes, embedded } = this.props;

        const item = this.getItem();

        this.state = {item: item}; 
            
        const header = embedded ? (null) : (
            <Typography variant="h6" color="inherit" noWrap>
                {this.model.name}
            </Typography>
        );

        const footer = embedded ? (null) : (
                <div>
                <Divider />
                {this.itemId &&
                    <ObjectInfoLine object={item} />
                }

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center" >
                <Button variant="contained" size="small" className={classes.button} onClick={this.handleEdit}>
                    <SaveIcon />
                    Modifier
                </Button>
                </Grid>
                </div>
        );

        const objectContent =  this.props.model.view.component ? (<CustomReadObject />) : (
            <ReadObject
                            dispatch={this.dispatch}
                            scope={this.scope + "_object"}
                            fields={this.fields}
                            object={item}
                            model={this.props.model}
                        />
        );

        return (
            <div>
                {header}
                {item &&
                <div>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        {objectContent}
                    </Grid>
                </Grid>
                {footer}
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    
    return {
        itemId,
        data
    };
}

const connectedItemReadPage = withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ItemReadPage));
export { connectedItemReadPage as ItemReadPage };