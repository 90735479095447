import { endpoint } from '../_helpers';
import { u2fService } from '../_services';
import { commonService } from '../_services';

export const userService = {
    login,
    logout,
    checkValidationAccessToken,
    validateAccessAndSetPassword,
    requestU2F,
    reponseU2F,
    registerU2F,
    signU2FChallenges,
    sendValidationEmail,
    rememberme,
};

const config = {
    apiUrl: endpoint
}

function login(username, password, rememberme) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "email": username, password })
    };

    return fetch(`${config.apiUrl}/login`, requestOptions)
        .then(handleResponse)
        .then(result => {
            // login successful if there's a jwt token in the response
            if (result.loggedIn) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(result));
                //return result.user;
            }
            if (result.authentified) {
                if (rememberme) {
                    localStorage.setItem('rememberme', username);
                } else {
                    localStorage.removeItem('rememberme')
                }
            }
            return result;
        });
}

function rememberme() {
    return localStorage.getItem('rememberme');
}

function requestU2F(token) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "token": token })
    };

    return fetch(`${config.apiUrl}/u2f/registrationRequest`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });

}

function reponseU2F(u2frequest) {
    return u2fService.register(u2frequest.registrationRequest).then( result => {
        return result;
    });
}

function registerU2F(token, id, registrationResponse, password, confirmPassword) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, id, registrationResponse, password, confirmPassword })
    };

    return fetch(`${config.apiUrl}/u2f/registration`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return Promise.resolve(true);
        });

}

function signU2FChallenges(token, challenges) {
    return u2fService.sign(challenges).then( signature => {
        console.log('signature => ', signature);
        return sign(token, signature);
    });
}

function checkValidationAccessToken(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "token": token })
    };

    return fetch(`${config.apiUrl}/checkAccessToken`, requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log('checkAccessToken result', user);
            return user;
        });
}

function validateAccessAndSetPassword(token, password, confirmPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, password, confirmPassword })
    };

    return fetch(`${config.apiUrl}/accessValidationWithPassword`, requestOptions)
        .then(handleResponse)
        .then(result => {
            console.log('accessValidationWithPassword result', result);
            return result;
        });
}

function sign(token, signature) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, signature })
    };

    return fetch(`${config.apiUrl}/u2f/sign`, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.loggedIn) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(result));
                return result;
            } else return null;
        });
}

function sendValidationEmail(userAccess) {

    const send = {
        userAccess: userAccess
    }

    const model  = {
        name: 'userAccessValidationSend',
        creation: {
            fields: [
                {name: 'userAccess', sourcetype: 'object', source:'userAccess'}
            ]
        }
    }

    return commonService.save(true, send, model)
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout(true);
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        console.log('handleReponse', data);
        return data;
    });
}