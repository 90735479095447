import { endpoint } from '../_helpers';
import { u2f } from 'u2f-api-polyfill';

export const u2fService = {
    register,
    sign
};

const config = {
    apiUrl: endpoint
}

function register(registrationRequest) {
    return new Promise(function(resolve, reject) {
    window.u2f.register(registrationRequest.appId, [registrationRequest], [], (res) => {
        console.log(res);
        
        if (res.errorCode) {
            reject('U2F Error');
        } else {
            resolve(res);
            //return sendRegistrationResult(token, u2fID, res, password, confirmPassword);
        } 

    });
    });
}

/*
function register(token) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "token": token })
    };

    var url = new URL(config.apiUrl + `/u2f/registrationRequest`);

    return fetch(url, requestOptions).then(response => {
        return response.text().then(text => {
            return new Promise(function(resolve, reject) {
                const data = text && JSON.parse(text);
                if (!response.ok) {
        
                    const error = (data && data.message) || response.statusText;
                    reject(error);
                }
                console.log('handleReponse', data);

                const registrationRequest = data.registrationRequest;
                const u2fID = data.id;

                window.u2f.register(registrationRequest.appId, [registrationRequest], [], (res) => {
                    console.log(res);
                    resolve(!res.errorCode, res)
                    if (res.errorCode) {
                        reject('U2F Error');
                    } else {
                        return sendRegistrationResult(token, u2fID, res, password, confirmPassword);
                    } 

                });
            });
        })
    });
}
*/


function sign(authRequestArray) {
    return doSign(authRequestArray, 0)
}

function doSign(authRequestArray, index) {
    console.log('try to sign key at index ', index);
    const authRequest = authRequestArray[index];
    return new Promise(function(resolve, reject) {
        window.u2f.sign(authRequest.appId, authRequest.challenge, [authRequest], (res) => {
            if (!res.errorCode) {
                console.log('successfully sign key at index ', index);
                resolve(res);
            } else if ((index + 1) < authRequestArray.length && res.errorCode !== 5) { // errorCode === 5 => timeout
                console.log('error on sign key at index ', index, res);
                return doSign(authRequestArray, index + 1);
            } else {
                reject();
            }
        });
    });
}