
import React from 'react';
import { connect } from 'react-redux';
import EnhancedTable from '../../_components/EnhancedTable';
import { ItemEditPage } from './EditItem';
import Button from '@material-ui/core/Button';
import { EditObject } from './EditObject';

class ChoiceList extends React.Component {

    constructor(props) {
        super(props)
        this.model = this.props.model;
        this.columns = this.model.list.fields.map(field => { return {id: field, numeric: false, disablePadding: true, label: field }});
        this.newObjects = {};
    }

    componentDidMount() {
    }

    insertObject = (item, editing) => {
        const obj = { value: item, editing: editing };
        this.itemsById[item.id] = obj;
    }

    handleSelect = (_items) => {
        console.log(this.model.name + ' handleSelect');
        if (this.props.handleSelect) {
            console.log(this, _items, this.props.items);
            //const objectItems = _items.map(itemId => this.itemsById[itemId].value);
            //objectItems.push({id:'test', vin: 'ok'});
            //console.log(this.model.name + ' handleSelect objectItems', objectItems);
            
            this.props.handleSelect(this.props.items.filter( item => _items.find ( itemId => item.id === itemId )));
        }
    }

    handleChangeItemEdition = item => {
        //console.log('item updated ', item);
    }

    handleAddNewItem = e => {
        //console.log('add new item ');
        if (this.props.handleSelect) {
            const objectItems = this.props.selectedItems;
            const obj = {};
            //this.insertObject(obj, true);
            objectItems.push(obj);
            this.props.handleSelect(objectItems);
        }
    }

    handleChangeSubObject = (object, obj) => {
        console.log('ChoiceList handle change from sub ', object, obj, this.props.selectedItems, this.itemsById);
        this.handleSelect(this.props.selectedItems.map( item => item.id ));
    }

    render() {
        const { items, selectedItems, classes } = this.props;
        const list = this.model.list;
        const _selectedItems = selectedItems ? selectedItems.filter( obj => obj).map(obj => obj.id) : null;
        
        //console.log('RENDER ChoiceList ' + this.model.name + ' _selectedItems ', _selectedItems);

        let content;
        if (this.props.canCreate && this.props.embedded) {
            let edition = this.props.selectedItems ? this.props.selectedItems.map( (item, index) => {
                return (
                    <EditObject
                        classes={classes}
                        fields={this.props.model.creation.fields}
                        object={item}
                        handleChange={ object => {
                            //this.handleChangeSubObject(this.props.inFieldName, object);
                            console.log('embedded change ', object, this.props.selectedItems);
                            this.props.handleSelect(this.props.selectedItems);
                        }}
                    />
                );
            }) : (null);
            

            
            content = (
                <div>
                    <Button onClick={this.handleAddNewItem}>Nouveau</Button>
                    {edition}
                </div>
                
            );
        } else {
            content = (
                <div>
                {
                    items &&
                    <EnhancedTable
                        model={this.model}
                        columns={this.columns}
                        rows={items}
                        tableTitle={list.title || this.model.name}
                        selectedItems={_selectedItems}
                        handleSelect={this.handleSelect}
                        withPagination={true}
                    />
                }
                </div>
            );
        }

        return content;
    }
}

function mapStateToProps(state) {
    const { data } = state;
    return {
        data
    };
}

const connectedChoiceListPage = connect(mapStateToProps)(ChoiceList);
export { connectedChoiceListPage as ChoiceList };