import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../_helpers';
import { PrivateRoute } from '../_components';
import { LoginPage } from '../_pages/global/LoginPage';
import { ValidEmailAccountPage } from '../_pages/global/ValidEmailAccountPage';
import { HomePage } from '../HomePage';

import './App.css';

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {  } = this.props;

        return (
            <div>
                <Router history={history} >
                    <div>
                    <Switch>
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/validateEmail/:token" component={ValidEmailAccountPage} />
                        <PrivateRoute path="/" component={HomePage}/>
                    </Switch>
                    </div>
                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 