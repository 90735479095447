import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserView } from '../../../_components/custom/UserView'

class CustomReadObject extends Component {
    components = {
        userView: UserView
    };
    render() {
       const TagName = this.components[this.props.tag || 'userView'];
       return <TagName />
    }
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    
    return {
        itemId,
        data
    };
}

const connectedCustomReadObject = connect(mapStateToProps)(CustomReadObject);
export { connectedCustomReadObject as CustomReadObject };