import { commonConstants } from '../_constants';
import { commonService } from '../_services';
import { history } from '../_helpers';

export const commonActions = {
    getAll,
    exportAll,
    read,
    edit,
    create,
    updateCurrentEdition,
    save,
    deleteItem,
    goCreate,
    goEdit,
    goRead
};

function getAll(scope, model, key, filters) {
    return dispatch => {
        dispatch(request());

        commonService.getAll(model, filters)
            .then(
                items => dispatch(success(items)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.GETALL_REQUEST, scope, model } }
    function success(items) { return { type: commonConstants.GETALL_SUCCESS, scope, items, model, key: key } }
    function failure(error) { return { type: commonConstants.GETALL_FAILURE, scope, error, model } }
}

function exportAll(scope, model, key, filters) {
    return dispatch => {
        //dispatch(request());

        commonService.exportAll(model, filters)
            .then(
                items => console.log(items),
                error => console.log(error)
            );
    };

    /*function request() { return { type: commonConstants.GETALL_REQUEST, scope, model } }
    function success(items) { return { type: commonConstants.GETALL_SUCCESS, scope, items, model, key: key } }
    function failure(error) { return { type: commonConstants.GETALL_FAILURE, scope, error, model } }*/
}

function read(scope, id, model) {
    return dispatch => {
        dispatch(request());

        commonService.getOne(id, model)
            .then(
                item => dispatch(success(item)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.READ_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.READ_SUCCESS, scope, item, model } }
    function failure(error) { return { type: commonConstants.READ_FAILURE, scope, error, model } }
}

function edit(scope, id, model) {
    return dispatch => {
        dispatch(request());

        commonService.getOne(id, model)
            .then(
                item => dispatch(success(item)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.EDIT_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.EDIT_SUCCESS, scope, item, model } }
    function failure(error) { return { type: commonConstants.EDIT_FAILURE, scope, error, model } }
}

function create(scope, model, parent) {
    return dispatch => {
        dispatch(request());

        commonService.create(model, parent)
            .then(
                item => {
                    dispatch(success(item));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.CREATE_REQUEST, scope, model, parent } }
    function success(item) { return { type: commonConstants.CREATE_SUCCESS, scope, item, model, parent } }
    function failure(error) { return { type: commonConstants.CREATE_FAILURE, scope, error, model, parent } }
}

function save(scope, creation, item, model, redirectUrl) {
    return dispatch => {
        dispatch(request());

        commonService.save(creation, item, model)
            .then(
                item => {
                    dispatch(success(item));
                    if (redirectUrl) history.push(redirectUrl);
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.SAVE_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.SAVE_SUCCESS, scope, item, model } }
    function failure(error) { return { type: commonConstants.SAVE_FAILURE, scope, error, model } }
}

function deleteItem(scope, item, model) {
    return dispatch => {
        dispatch(request());

        commonService.deleteItem(item, model)
            .then(
                deleted => dispatch(success(item)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.DELETE_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.DELETE_SUCCESS, scope, item, model } }
    function failure(error) { return { type: commonConstants.DELETE_FAILURE, scope, error, model } }
}

function updateCurrentEdition(scope, updatedValues, path, model, callback) {
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: commonConstants.UPDATE_ITEM_EDITION_REQUEST, scope, updatedValues, path, model, callback } }
}

function goCreate(model, parent) {
    return dispatch => {
        dispatch(request());
        history.push('/edit/' + model.name);
    };

    function request() { return { type: commonConstants.GO_CREATE_REQUEST, model, parent } }
}

function goEdit(model, object, parent) {
    return () => {
        history.push('/edit/' + model.name + '/' + object.id);
    };
}

function goRead(model, object, parent) {
    return () => {
        history.push('/read/' + model.name + '/' + object.id);
    };
}