import { commonConstants } from '../_constants';

function updateObject(object, path, newValue) {
  if (path && path.length > 0) {
    const currentPath = path.shift();
    let subObject = object[currentPath];
    if (subObject === undefined || subObject === null) {
      subObject = {};
      object[currentPath] = subObject;
    }
    object[currentPath] = updateObject(object[currentPath], path, newValue);
    return object;
  } else {
    return {...object, ...newValue};
  }
}

function wrapInScope(state, action, object) {
  if (action.scope) {
    var result = {}
    result[action.scope] = object; 
    return {...state, ...result};
  } else {
    return object;
  }
}

function extractStateFromScope(state, action) {
  if (action.scope) {
    let result = state[action.scope];
    if (!result) {
      result = {};
    }
    return result;
  } else {
    return state;
  }
}

function doReduce(state, action) {
  switch (action.type) {
    case commonConstants.GETALL_REQUEST:
      return {
        loading: true,
        model: action.model
      };
    case commonConstants.GETALL_SUCCESS:
      const result = {
        model: action.model
      };
      if (action.key) {
        const sources = state.sources ? state.sources : {};
        sources[action.key] = action.items;
        result.sources = sources;
        return {...state, ...result};
      } else{
        result['items'] = action.items;
      } 
      return result;
    case commonConstants.GETALL_FAILURE:
      return { 
        error: action.error,
        model: action.model
      };
    case commonConstants.DELETE_SUCCESS:
      return { 
        items: state.items.filter(item => item.id !== action.item.id),
        model: action.model
      };
      case commonConstants.CREATE_REQUEST:
      return {
        loading: true,
        model: action.model
      };
    case commonConstants.CREATE_SUCCESS:
      return {
        item: action.item,
        model: action.model,
        sources: state.sources
      };
    case commonConstants.CREATE_FAILURE:
      return { 
        error: action.error,
        model: action.model
      };
      case commonConstants.READ_REQUEST:
      return {
        loading: true,
        model: action.model
      };
    case commonConstants.READ_SUCCESS:
      return {
        item: action.item,
        model: action.model
      };
    case commonConstants.READ_FAILURE:
      return { 
        error: action.error,
        model: action.model
      };
    case commonConstants.EDIT_REQUEST:
      return {
        loading: true,
        model: action.model
      };
    case commonConstants.EDIT_SUCCESS:
      return {
        item: action.item,
        model: action.model,
        sources: state.sources
      };
    case commonConstants.EDIT_FAILURE:
      return { 
        error: action.error,
        model: action.model
      };
    case commonConstants.UPDATE_ITEM_EDITION_REQUEST:
      let newItem;
      if (action.path && action.path.length > 0) {
        newItem = updateObject(state.item, action.path, action.updatedValues);
      } else {
        newItem = {...state.item, ...action.updatedValues}
      }

      if (action.callback) {
        action.callback(newItem);
      }

      return {
        item: newItem,
        model: action.model,
        sources: state.sources
      };
      case commonConstants.SAVE_REQUEST:
      return {
        saving: true,
        model: action.model
      };
    case commonConstants.SAVE_SUCCESS:
      return {
        item: action.item,
        model: action.model
      };
    case commonConstants.SAVE_FAILURE:
      return { 
        error: action.error,
        model: action.model
      };
    case commonConstants.GO_CREATE_REQUEST:
        return { 
          parent: action.parent
        };
    default:
      return state
  }
}

export function data(state = {}, action) {

  const newState = wrapInScope(state, action, doReduce(extractStateFromScope(state, action), action));

  return newState;

}