import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import i18n from '../_i18n/i18n';
import { translate } from 'react-i18next';

import moment from 'moment'
require("moment/min/locales.min");


const styles = theme => ({
    margin: {
      margin: '1rem',
    }
});

class ObjectInfoLine extends React.Component {

    render () {

        const { t, classes, object } = this.props;
        moment.locale(i18n.language);
        const createdAt = object && object._metadata ? moment(object._metadata.timestamps.createdAt).format('LLL') : '-';
        const updatedAt = object && object._metadata ? moment(object._metadata.timestamps.updatedAt).format('LLL') : '-';

        return (
            <Typography variant="subtitle2" color="textSecondary" className={classes.margin}>
                {t('created.on')} {createdAt} - {t('last.update.on')} {updatedAt}
            </Typography>
        );

    }

}

ObjectInfoLine.propTypes = {
    classes: PropTypes.object.isRequired,
    object: PropTypes.object.isRequired,
};
  
export default translate('translations')(withStyles(styles)(ObjectInfoLine));