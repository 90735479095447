import moment from 'moment';

export const Objects = [
    {name: 'websession', menu: true , fields: {
        'id': {create: false, update: false},
        'lastaccess': {create: true, update: false, required: true},
        'access': {create: true, update: true, computed: o => { return o.access.user.email }},
    }, list: {
        fields: ['id', 'lastaccess', 'access']
    }, view: {
        fields: [
            {name: 'id', type: 'text'},
            {name: 'lastaccess', type: 'date'},
        ]
    }, toString: o => o.id },
    {name: 'serveurConfiguration', menu: true , fields: {
        'id': {create: false, update: false},
        'name': {create: true, update: true, required: true},
		'serviceProvider': {create: true, update: true, object:'serviceProvider'},
				
		'ovhSmsService': {create: true, update: true, required: false},
		'ovhSmsSender': {create: true, update: true, required: false},
		'ovhPhoneService': {create: true, update: true, required: false},
		'ovhBillingAccount': {create: true, update: true, required: false},
		'ovhAppKey': {create: true, update: true, required: false},
		'ovhAppSecret': {create: true, update: true, required: false},
		'ovhConsumerKey': {create: true, update: true, required: false},
		'mailSmtpHost': {create: true, update: true, required: false},
		'mailSmtpPort': {create: true, update: true, required: false},
		'mailSmtpSecure': {type: 'boolean', create: true, update: true, required: false},
		'mailSmtpUser': {create: true, update: true, required: false},
		'mailSmtpPwd': {create: true, update: true, required: false},
		'mailExportAddress': {create: true, update: true, required: false},
		'mailNoReplyAddress': {create: true, update: true, required: false},
		
		'useDefOvhSmsService': {type: 'boolean', create: true, update: true, required: false},
		'useDefOvhSmsSender': {type: 'boolean', create: true, update: true, required: false},
		'useDefOvhPhoneService': {type: 'boolean', create: true, update: true, required: false},
		'useDefOvhBillingAccount': {type: 'boolean', create: true, update: true, required: false},
		'useDefOvhAppKey': {type: 'boolean', create: true, update: true, required: false},
		'useDefOvhAppSecret': {type: 'boolean', type: 'boolean', create: true, update: true, required: false},
		'useDefOvhConsumerKey': {type: 'boolean', create: true, update: true, required: false},
		'useDefMailSmtpHost': {type: 'boolean', create: true, update: true, required: false},
		'useDefMailSmtpPort': {type: 'boolean', create: true, update: true, required: false},
		'useDefMailSmtpSecure': {type: 'boolean', create: true, update: true, required: false},
		'useDefMailSmtpUser': {type: 'boolean', create: true, update: true, required: false},
		'useDefMailSmtpPwd': {type: 'boolean', create: true, update: true, required: false},
		'useDefMailExportAddress': {type: 'boolean', create: true, update: true, required: false},
		'useDefMailNoReplyAddress': {type: 'boolean', create: true, update: true, required: false}
		
    }, list: {
        fields: ['name', 'serviceProvider']
    }, view: {
        fields: [
            {name: 'name'},
			{name: 'serviceProvider'},
			{name: 'ovhSmsService'},
			{name: 'ovhSmsSender'},
			{name: 'ovhPhoneService'},
			{name: 'ovhBillingAccount'},
			{name: 'ovhAppKey'},
			{name: 'ovhAppSecret'},
			{name: 'ovhConsumerKey'},
			{name: 'mailSmtpHost'},
			{name: 'mailSmtpPort'},
			{name: 'mailSmtpSecure'},
			{name: 'mailSmtpUser'},
			{name: 'mailSmtpPwd'},
			{name: 'mailExportAddress'},
			{name: 'mailNoReplyAddress'},
			{name: 'useDefOvhSmsService'},
			{name: 'useDefOvhSmsSender'},
			{name: 'useDefOvhPhoneService'},
			{name: 'useDefOvhBillingAccount'},
			{name: 'useDefOvhAppKey'},
			{name: 'useDefOvhAppSecret'},
			{name: 'useDefOvhConsumerKey'},
			{name: 'useDefMailSmtpHost'},
			{name: 'useDefMailSmtpPort'},
			{name: 'useDefMailSmtpSecure'},
			{name: 'useDefMailSmtpUser'},
			{name: 'useDefMailSmtpPwd'},
			{name: 'useDefMailExportAddress'},
			{name: 'useDefMailNoReplyAddress'}
        ]
    }, creation: {
        fields: [
            {name: 'name'},
			{name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
			{name: 'ovhSmsService'},
			{name: 'ovhSmsSender'},
			{name: 'ovhPhoneService'},
			{name: 'ovhBillingAccount'},
			{name: 'ovhAppKey'},
			{name: 'ovhAppSecret'},
			{name: 'ovhConsumerKey'},
			{name: 'mailSmtpHost'},
			{name: 'mailSmtpPort'},
			{name: 'mailSmtpSecure'},
			{name: 'mailSmtpUser'},
			{name: 'mailSmtpPwd'},
			{name: 'mailExportAddress'},
			{name: 'mailNoReplyAddress'},
			{name: 'useDefOvhSmsService'},
			{name: 'useDefOvhSmsSender'},
			{name: 'useDefOvhPhoneService'},
			{name: 'useDefOvhBillingAccount'},
			{name: 'useDefOvhAppKey'},
			{name: 'useDefOvhAppSecret'},
			{name: 'useDefOvhConsumerKey'},
			{name: 'useDefMailSmtpHost'},
			{name: 'useDefMailSmtpPort'},
			{name: 'useDefMailSmtpSecure'},
			{name: 'useDefMailSmtpUser'},
			{name: 'useDefMailSmtpPwd'},
			{name: 'useDefMailExportAddress'},
			{name: 'useDefMailNoReplyAddress'}
        ]
    }, edition: {
        fields: [
            {name: 'name'},
			{name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
			{name: 'ovhSmsService'},
			{name: 'ovhSmsSender'},
			{name: 'ovhPhoneService'},
			{name: 'ovhBillingAccount'},
			{name: 'ovhAppKey'},
			{name: 'ovhAppSecret'},
			{name: 'ovhConsumerKey'},
			{name: 'mailSmtpHost'},
			{name: 'mailSmtpPort'},
			{name: 'mailSmtpSecure'},
			{name: 'mailSmtpUser'},
			{name: 'mailSmtpPwd'},
			{name: 'mailExportAddress'},
			{name: 'mailNoReplyAddress'},
			{name: 'useDefOvhSmsService'},
			{name: 'useDefOvhSmsSender'},
			{name: 'useDefOvhPhoneService'},
			{name: 'useDefOvhBillingAccount'},
			{name: 'useDefOvhAppKey'},
			{name: 'useDefOvhAppSecret'},
			{name: 'useDefOvhConsumerKey'},
			{name: 'useDefMailSmtpHost'},
			{name: 'useDefMailSmtpPort'},
			{name: 'useDefMailSmtpSecure'},
			{name: 'useDefMailSmtpUser'},
			{name: 'useDefMailSmtpPwd'},
			{name: 'useDefMailExportAddress'},
			{name: 'useDefMailNoReplyAddress'}
        ]
    }, toString: o => o.name },
	{name: 'userRole', menu: true , fields: {
        'id': {create: false, update: false},
        'name': {create: true, update: false, required: true},
        'profil': {create: true, update: true},
        'operator': {create: true, update: true, object:'operator'},
        'serviceProvider': {create: true, update: true, object:'serviceProvider'},
        'customer': {create: true, update: true, object:'customer'},
        'features': {create: true, update: true, array: true, object:'feature'},
        'objectRights': {create: true, update: true, array: true, child: true, object:'objectRight'},
        'availableRoles': {create: true, update: true, array: true, object:'userRole'},
    }, list: {
        fields: ['name', 'profil', 'operator', 'serviceProvider', 'customer']
    }, view: {
        fields: [
            {name: 'operator', type: 'choice', sourcetype: 'object', source:'operator'},
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'customer', type: 'choice', sourcetype: 'object', source:'customer'},
            //{name: 'profil', type: 'choice', sourcetype: 'value', source: {'ADMIN': 'profil.administrator', 'SERVICE_MANAGER': 'profil.service_provider', 'FLEET_MANAGER': 'profil.fleet_manager'}},
            {name: 'name', type: 'text'},
            {name: 'features', type: 'list'},
            {name: 'objectRights', type: 'list'},
            {name: 'availableRoles', type: 'list'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text'},
            {name: 'operator', type: 'choice', sourcetype: 'object', source:'operator'},
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'customer', type: 'choice', sourcetype: 'object', source:'customer'},
            {name: 'features', type: 'choicelist', sourcetype: 'object', source:'feature'},
            {name: 'availableRoles', type: 'choicelist', sourcetype: 'object', source:'userRole'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text'},
            {name: 'operator', type: 'choice', sourcetype: 'object', source:'operator'},
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'customer', type: 'choice', sourcetype: 'object', source:'customer'},
            {name: 'features', type: 'choicelist', sourcetype: 'object', source:'feature'},
            {name: 'availableRoles', type: 'choicelist', sourcetype: 'object', source:'userRole'},
        ]
    }, toString: (o, t) => (t ? t(o.name) : o.name) + " (" + (t ? t("profil."+o.profil) : o.profil)+ ")" },
    {name: 'feature', menu: true , fields: {
        'id': {create: false, update: false},
        'name': {},
        'description': {},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['name', 'description']
    }, view: {
        fields: [
            {name: 'name', type: 'text'},
            {name: 'description', type: 'text'}
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text'},
            {name: 'description', type: 'text'}
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text'},
            {name: 'description', type: 'text'}
        ]
    }, toString: o => o.name },
    {name: 'object', menu: false , fields: {
        'id': {create: false, update: false},
        'name': {},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, toString: o => o.name },
    {name: 'action', menu: false , fields: {
        'id': {create: false, update: false},
        'name': {},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, toString: o => o.name },
    {name: 'objectRight', menu: false , fields: {
        'id': {create: false, update: false},
        'object': {create: true, update: false, required: true, object:'object'},
        'role': {create: true, update: true},
        'list': {type: 'boolean'},
        'read': {type: 'boolean'},
        'create': {type: 'boolean'},
        'update': {type: 'boolean'},
        'delete': {type: 'boolean'},
        'archive': {type: 'boolean'},
        'export': {type: 'boolean'},
        'kpi': {type: 'boolean'},
        'actions': {array: true, object:'actionRight'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['object', 'list', 'read', 'create', 'update', 'delete', 'archive', 'export', 'kpi']
    }, view: {
        fields: [
            {name: 'object', type: 'choice', sourcetype: 'object', source:'object'},
            {name: 'list'},
            {name: 'read'},
            {name: 'create'},
            {name: 'update'},
            {name: 'delete'},
            {name: 'archive'},
            {name: 'export'},
            {name: 'kpi'},
            {name: 'actions', type: 'list'},
        ]
    }, creation: {
        fields: [
            {name: 'object', type: 'choice', sourcetype: 'object', source:'object'},
            {name: 'list'},
            {name: 'read'},
            {name: 'create'},
            {name: 'update'},
            {name: 'delete'},
            {name: 'archive'},
            {name: 'export'},
            {name: 'kpi'},
        ]
    }, edition: {
        fields: [
            {name: 'object', type: 'choice', sourcetype: 'object', source:'object'},
            {name: 'list'},
            {name: 'read'},
            {name: 'create'},
            {name: 'update'},
            {name: 'delete'},
            {name: 'archive'},
            {name: 'export'},
            {name: 'kpi'},
        ]
    }},
    {name: 'actionRight', menu: false , fields: {
        'id': {},
        'objectRight': {object:'objectRight'},
        'role': {object: 'userRole'},
        'action': {object: 'action'},
        'right': {type: 'boolean'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['action', 'right']
    }, view: {
        fields: [
            {name: 'action', type: 'choice', sourcetype: 'object', source:'action'},
            {name: 'right'},
        ]
    }, creation: {
        fields: [
            //{name: 'action', type: 'choice', sourcetype: 'object', source:'action'},
            {name: 'right'},
        ]
    }, edition: {
        fields: [
            //{name: 'action', type: 'choice', sourcetype: 'object', source:'action'},
            {name: 'right'},
        ]
    }},
    {name: 'user', menu: true , fields: {
        'id': {create: false, update: false},
        'operator': {create: true, update: true, object:'operator'},
        'serviceProvider': {create: true, update: true, object:'serviceProvider'},
        'customer': {create: true, update: true, object:'customer'},
        'email': {create: true, update: false},
        'password': {create: true, update: false, hidden: true},
        'firstName': {create: true, update: true},
        'lastName': {create: true, update: true},
        'phone': {create: true, update: true},
        'profils': {create: true, update: true, array: true},
        'roles': {create: true, update: true, array: true, object:'userRole', filter: [{
            field: 'operator',
            path: 'operator.id'
        }, {
            field: 'serviceProvider',
            path: 'serviceProvider.id'
        }, {
            field: 'customer',
            path: 'customer.id'
        }]},
        'lastAccess': {create: true, update: true},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['firstName', 'lastName', 'email', 'operator', 'serviceProvider', 'customer', 'roles', 'lastAccess']
    }, view: {
        fields: [
            
            {name: 'roles', type: 'choice', mulitple: true, sourcetype: 'object', source:'userRole'},
            
            {name: 'email', type: 'text', readonly: true},
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'phone', type: 'text', required: true}
        ],
        component: 'UserView'
    }, creation: {
        fields: [
            {name: 'operator', type: 'choice', sourcetype: 'object', source:'operator'},
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'customer', type: 'choice', sourcetype: 'object', source:'customer'},
            
            {name: 'profils', type: 'choice', multiple: true, sourcetype: 'value', source: {'ADMIN': 'profil.administrator', 'STATION_OPERATOR': 'profil.station_operator', 'SERVICE_MANAGER': 'profil.service_provider', 'FLEET_MANAGER': 'profil.fleet_manager'}},
            {name: 'roles', type: 'choice', multiple: true, sourcetype: 'object', source:'userRole'},
            
            {name: 'email', type: 'text', required: true},
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'phone', type: 'text', required: true},
        ]
    }, edition: {
        fields: [
            
            {name: 'profils', type: 'choice', multiple: true, sourcetype: 'value', source: {'ADMIN': 'profil.administrator', 'STATION_OPERATOR': 'profil.station_operator', 'SERVICE_MANAGER': 'profil.service_provider', 'FLEET_MANAGER': 'profil.fleet_manager'}},
            
            {name: 'roles', type: 'choice', multiple: true, sourcetype: 'object', source:'userRole'},
            {name: 'email', type: 'text', readonly: true},
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'phone', type: 'text', required: true}
        ]
    }},
    {name: 'userU2F', menu: false, fields: {
        'id': {},
        'name': {},
        'ready': {type: 'boolean', displayName: 'associated'},
        'enabled': {type: 'boolean'},
        'createdAt': {type: 'date', displayName: 'added.on'},
        'updatedAt': {},
        'registrationResponse': {required: true, type: 'u2fregistration', displayName: 'registration'},
    }, list: {
        fields: ['createdAt', 'name', 'ready', 'enabled']
    }, creation: {
        fields: [
            {name: 'name'},
            {name: 'registrationResponse'}
        ]
    }, edition: {
        fields: [
            {name: 'name'}
        ]
    }, rights: {
        ADMIN: {
            ADMIN: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            MANAGER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            VIEWER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
        },
        SERVICE_MANAGER: {
            ADMIN: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            MANAGER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            VIEWER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
        },
        FLEET_MANAGER: {
            ADMIN: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            MANAGER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            VIEWER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
        }
    }
    },
    {name: 'userTOTP', menu: false, fields: {
        'id': {},
        'name': {},
        'ready': {type: 'boolean', displayName: 'associated'},
        'enabled': {type: 'boolean'},
        'createdAt': {type: 'date', displayName: 'added.on'},
        'updatedAt': {},
        'token': {required: true, type: 'totpregistration', displayName: 'token'},
    }, list: {
        fields: ['createdAt', 'name', 'ready', 'enabled']
    }, creation: {
        fields: [
            {name: 'name'},
            {name: 'token'}
        ]
    }, edition: {
        fields: [
            {name: 'name'}
        ]
    }, rights: {
        ADMIN: {
            ADMIN: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            MANAGER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            VIEWER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
        },
        SERVICE_MANAGER: {
            ADMIN: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            MANAGER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            VIEWER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
        },
        FLEET_MANAGER: {
            ADMIN: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            MANAGER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
            VIEWER: { list: true, read: false, create: true, edit: true, delete: true, archive: true },
        }
    }
    },
    {name: 'operator', menu: true, fields: {
        'id': {create: false, update: false},
        'publicId': {create: false, update: false},
        'name': {create: true, update: true},
        'img': {create: true, update: true},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['publicId', 'name']
    }, view: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true}
        ]
    }, creation: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true}
        ]
    }, edition: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true}
        ]
    },
    toString: object => {return object.name}},
    {name: 'serviceProvider', menu: true, fields: {
        'id': {create: false, update: false},
        'publicId': {create: false, update: false},
        'name': {create: true, update: true},
        'supportphone': {regexp:/^[0-9]{10}$/},
        'inscriptionNotification': {create: true, update: true},
        'inscriptionNotificationReceivers': {create: true, update: true},
        'sendEmailUserOnCreation': {create: true, update: true},
        'apiClient': {create: true, update: true, child: true, object:'apiClient'},
        'customers': {create: true, update: true, array: true, child: true, object:'customer'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['publicId', 'name', 'supportphone']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'supportphone', type: 'text'},
            {name: 'inscriptionNotification', type: 'boolean', required: true},
            {name: 'inscriptionNotificationReceivers', type: 'text'},
            {name: 'sendEmailUserOnCreation', type: 'boolean', required: true},
            {name: 'customers', type: 'list'},
            {name: 'apiClient'},
        ]
    }, creation: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'supportphone', type: 'text'},
            {name: 'inscriptionNotification', type: 'boolean', required: true},
            {name: 'inscriptionNotificationReceivers', type: 'text'},
            {name: 'sendEmailUserOnCreation', type: 'boolean', required: true},
        ]
    }, edition: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'supportphone', type: 'text'},
            {name: 'inscriptionNotification', type: 'boolean', required: true},
            {name: 'inscriptionNotificationReceivers', type: 'text'},
            {name: 'sendEmailUserOnCreation', type: 'boolean', required: true},
        ]
    },
    toString: object => {return object.name}},
    {name: 'energy', menu: true, fields: {
        'id': {create: false, update: false},
        'name': {create: true, update: true},
        'types': {create: true, update: true, array: true, child: true, object:'energyType'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'types', type: 'ownlist', sourcetype: 'object', source:'energyType'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'types', type: 'ownlist', sourcetype: 'object', source:'energyType'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'types', type: 'ownlist', sourcetype: 'object', source:'energyType'},
        ]
    },
    toString: object => {return object.name}},
    {name: 'energyType', fields: {
        'id': {create: false, update: false},
        'name': {create: true, update: true},
        'productReference': {create: true, update: true},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['name', 'productReference']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'productReference', type: 'text', required: true},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'productReference', type: 'text', required: true},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'productReference', type: 'text', required: true},
        ]
    }, toString: object => {return object.name}},
    {name: 'contract', menu: false, fields: {
        'id': {create: false, update: false},
        'name': {create: true, update: true},
        'customer': {create: false, update: false, object:'customer'},
        'type': {create: true, update: true, object:'contractType'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, parentField: 'customer'
    , list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'type', type: 'choice', sourcetype: 'object', source:'contractType'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'customer', hidden: true},
            {name: 'type', type: 'choice', sourcetype: 'object', source:'contractType'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'type', type: 'choice', sourcetype: 'object', source:'contractType'},
        ]
    },
    toString: object => {return object.name}},
    {name: 'contractType', menu: true, fields: {
        'id': {create: false, update: false},
        'name': {create: true, update: true},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true}
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true}
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true}
        ]
    },
    toString: object => {return object.name}},
    {name: 'customer', menu: true, fields: {
        'id': {create: false, update: false},
        'name': {create: true, update: true},
        'contracts': {create: true, update: true, array: true, child: true, object:'contract'},
        'fleets': {create: true, update: true, array: true, child: true, object:'fleet'},
        'serviceProvider': {create: false, update: false, object:'serviceProvider'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, parentField: 'serviceProvider', list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'contracts', type: 'list'},
            {name: 'fleets', type: 'list'},
        ]
    }, creation: {
        fields: [
            {name: 'serviceProvider', hidden: true},
            {name: 'name', type: 'text', required: true},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
        ]
    },
    toString: object => {return object.name}},
    {name: 'refillEndStatusConfig', menu: true, fields: {
        'id': {},
        'name': {},
        'energyTypes': {object:'energyType', array: true},
        'mappings': {object:'refillEndStatusMapping', required: true, array: true, child: true},
    }, list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'energyTypes', type: 'choice', multiple: true, sourcetype: 'object', source:'energyType'},
            {name: 'mappings', type: 'listchoice', sourcetype: 'object', source:'refillEndStatusMapping'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'energyTypes', type: 'choice', multiple: true, sourcetype: 'object', source:'energyType'},
            {name: 'mappings', type: 'ownlist', sourcetype: 'object', source:'refillEndStatusMapping'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'energyTypes', type: 'choice', multiple: true, sourcetype: 'object', source:'energyType'},
            {name: 'mappings', type: 'ownlist', sourcetype: 'object', source:'refillEndStatusMapping'},
        ]
    },
    toString: object => {return object.name}},
    {name: 'refillEndStatusMapping', menu: false, fields: {
        'id': {},
        'endStatus': {},
        'endSubStatus': {},
        'endSummaryStatus': {},
        'endSummarySubStatus': {},
    }, list: {
        fields: ['endStatus', 'endSubStatus', 'endSummaryStatus', 'endSummarySubStatus']
    }, view: {
        fields: [
            {name: 'endStatus', type: 'text', required: true},
            {name: 'endSubStatus', type: 'text', required: true},
            {name: 'endSummaryStatus', type: 'text', required: true},
            {name: 'endSummarySubStatus', type: 'text', required: true},
        ]
    }, creation: {
        fields: [
            {name: 'endStatus', type: 'text', required: true},
            {name: 'endSubStatus', type: 'text', required: true},
            {name: 'endSummaryStatus', type: 'text', required: true},
            {name: 'endSummarySubStatus', type: 'text', required: true},
        ]
    }, edition: {
        fields: [
            {name: 'endStatus', type: 'text', required: true},
            {name: 'endSubStatus', type: 'text', required: true},
            {name: 'endSummaryStatus', type: 'text', required: true},
            {name: 'endSummarySubStatus', type: 'text', required: true},
        ]
    },
    toString: object => {return object.endStatus+'/'+object.endSubStatus+' => '+object.endSummaryStatus+'/'+object.endSummarySubStatus}},
    {name: 'stationModel', menu: true, fields: {
        'id': {},
        'name': {},
        'refillEndStatusConfig': {object:'refillEndStatusConfig', required: true},
    }, list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'refillEndStatusConfig', type: 'choice', multiple: true, sourcetype: 'object', source:'refillEndStatusConfig'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'refillEndStatusConfig', type: 'choice', multiple: true, sourcetype: 'object', source:'refillEndStatusConfig'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'refillEndStatusConfig', type: 'choice', multiple: true, sourcetype: 'object', source:'refillEndStatusConfig'},
        ]
    },
    toString: object => {return object.name}},
    {name: 'station', menu: true, fields: {
        'id': {create: false, update: false},
        'publicId': {create: false, update: false},
        'reference': {create: false, update: false},
        'name': {create: true, update: true},
        'modele': {create: true, update: true},
        'model': {create: true, update: true, object: 'stationModel'},
        'public': {create: true, update: true},
        'h2mapShared': {create: true, update: true},
        'h2mapId': {create: true, update: true},
        'phone': {create: true, update: true},
        'operator': {create: true, update: true, object: 'operator'},
        'networks': {create: true, update: true, array: true, object: 'stationNetwork', filter: [{
            field: 'operator',
            path: 'operator.id'
        }]},
        'gps': { structure: {
                latitude: {},
                longitude: {},
            },
        },
        'address': { structure: {
                street: {},
                zipcode: {},
                city: {},
                country: {},
            },
        },
        'energys': {create: true, update: true, object: 'energy', array: true},
        'dispensers': {create: true, update: true, array: true, child: true, object:'dispenser'},
        'barriers': {create: true, update: true, array: true, child: true, object:'barrier'},
        'wifi': { structure: {
                ssid: {},
                password: {},
                hidden: {}
            },
        },
        'endpoint': { structure: {
                host: {},
                port: {},
                path: {}
            },
        },
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['operator', 'networks', 'publicId', 'name', 'phone', 'public']
    }, view: {
        fields: [
            {name: 'operator', type: 'choice', sourcetype: 'object', source:'operator', required: true},
            {name: 'publicId', type: 'text', required: true},
            {name: 'reference', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'text', required: true},
            {name: 'model', type: 'choice', sourcetype: 'object', source:'stationModel', required: false},
            {name: 'public', type: 'text', required: true},
            {name: 'networks', type: 'choice', multiple: true, sourcetype: 'object', source:'stationNetwork'},
            {name: 'h2mapShared', type: 'text'},
            {name: 'h2mapId', type: 'text'},
            {name: 'phone', type: 'text', required: true},
            {name: 'address', type: 'structure', required: true, fields: [
                {name: 'street', type: 'text', required: true},
                {name: 'zipcode', type: 'text', required: true},
                {name: 'city', type: 'text', required: true},
                {name: 'country', type: 'text', required: true},
            ]},
            {name: 'gps', type: 'structure', required: true, fields: [
                {name: 'latitude', type: 'text', required: true},
                {name: 'longitude', type: 'text', required: true}
            ]},
            {name: 'energys', type: 'listlight'},
            {name: 'dispensers', type: 'list'},
            {name: 'barriers', type: 'list'},
            {name: 'wifi', type: 'structure', required: true, fields: [
                {name: 'ssid', type: 'text', required: true},
                {name: 'password', type: 'text', required: true},
                {name: 'hidden', type: 'boolean', required: true}
            ]},
            {name: 'endpoint', type: 'structure', required: true, fields: [
                {name: 'host', type: 'text', required: true},
                {name: 'port', type: 'text', required: true},
                {name: 'path', type: 'text', required: true}
            ]},
        ]
    }, creation: {
        fields: [
            {name: 'operator', type: 'choice', sourcetype: 'object', source:'operator', required: true},
            {name: 'publicId', type: 'text', required: true},
            {name: 'reference', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'text', required: true},
            {name: 'model', type: 'choice', sourcetype: 'object', source:'stationModel', required: false},
            {name: 'public', type: 'text', required: true},
            {name: 'networks', type: 'choice', multiple: true, sourcetype: 'object', source:'stationNetwork'},
            {name: 'h2mapShared', type: 'text'},
            {name: 'h2mapId', type: 'text'},
            {name: 'phone', type: 'text'},
            {name: 'address', type: 'structure', required: true, fields: [
                {name: 'street', type: 'text', required: true},
                {name: 'zipcode', type: 'text', required: true},
                {name: 'city', type: 'text', required: true},
                {name: 'country', type: 'text', required: true},
            ]},
            {name: 'gps', type: 'structure', required: true, fields: [
                {name: 'latitude', type: 'text', required: true},
                {name: 'longitude', type: 'text', required: true}
            ]},
            {name: 'energys', type: 'choicelist', sourcetype: 'object', source:'energy'},
            {name: 'barriers', type: 'choicelist', sourcetype: 'object', source:'barrier', canCreate: true, embedded: true},
            {name: 'wifi', type: 'structure', required: true, fields: [
                {name: 'ssid', type: 'text', required: true},
                {name: 'password', type: 'text', required: true},
                {name: 'hidden', type: 'boolean', required: true}
            ]},
            {name: 'endpoint', type: 'structure', required: true, fields: [
                {name: 'host', type: 'text', required: true},
                {name: 'port', type: 'text', required: true},
                {name: 'path', type: 'text', required: true}
            ]},
        ]
    }, edition: {
        fields: [
            {name: 'operator', type: 'choice', sourcetype: 'object', source:'operator', required: true},
            {name: 'publicId', type: 'text', required: true},
            {name: 'reference', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'text', required: true},
            {name: 'model', type: 'choice', sourcetype: 'object', source:'stationModel', required: false},
            {name: 'public', type: 'text', required: true},
            {name: 'networks', type: 'choice', multiple: true, sourcetype: 'object', source:'stationNetwork'},
            {name: 'h2mapId', type: 'text'},
            {name: 'phone', type: 'text'},
            {name: 'address', type: 'structure', required: true, fields: [
                {name: 'street', type: 'text', required: true},
                {name: 'zipcode', type: 'text', required: true},
                {name: 'city', type: 'text', required: true},
                {name: 'country', type: 'text', required: true},
            ]},
            {name: 'gps', type: 'structure', required: true, fields: [
                {name: 'latitude', type: 'text', required: true},
                {name: 'longitude', type: 'text', required: true}
            ]},
            {name: 'energys', type: 'choicelist', sourcetype: 'object', source:'energy'},
            {name: 'barriers', type: 'choicelist', sourcetype: 'object', source:'barrier', canCreate: true, embedded: true},
            {name: 'wifi', type: 'structure', required: true, fields: [
                {name: 'ssid', type: 'text', required: true},
                {name: 'password', type: 'text', required: true},
                {name: 'hidden', type: 'boolean', required: true}
            ]},
            {name: 'endpoint', type: 'structure', required: true, fields: [
                {name: 'host', type: 'text', required: true},
                {name: 'port', type: 'text', required: true},
                {name: 'path', type: 'text', required: true}
            ]},
        ]
    }, toString: object => {return object.name}},
    {name: 'dispenser', fields: {
        'id': {create: false, update: false},
        'station': {create: false, update: false, object:'station'},
        'reference': {create: false, update: false},
        'name': {create: true, update: true},
        'label': {create: true, update: true},
        'energyTypes': {
            create: true, update: true, object: 'energyType', array: true, filter: [{
                field: 'energy',
                path: 'station.energys.id'
            }]
        },
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, parentField: 'station'
    , list: {
        fields: ['name', 'label']
    }, view: {
        fields: [
            {name: 'reference', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'label', type: 'text'},
        ]
    }, creation: {
        fields: [
            {name: 'reference', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'label', type: 'text'},
            {name: 'station', hidden: true},
            {name: 'energyTypes', type: 'choicelist', sourcetype: 'object', source:'energyType'},
        ]
    }, edition: {
        fields: [
            {name: 'reference', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'label', type: 'text'},
            {name: 'energyTypes', type: 'choicelist', sourcetype: 'object', source:'energyType'},
        ]
    },
    toString: object => {return object.name}},
    {name: 'barrier', fields: {
        'id': {create: false, update: false},
        'station': {create: false, update: false, object:'station'},
        'name': {create: true, update: true},
        'idrelay': {required: false},
        'phone': {required: true},
        'destphone': {required: true, displayName: 'barrier.destphone'},
        'wsphone': {required: true, displayName: 'barrier.wsphone'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, parentField: 'station'
    , list: {
        fields: ['name', 'phone']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'idrelay', type: 'text', required: true},
            {name: 'phone', type: 'text', required: true},
            {name: 'destphone', type: 'text', required: true},
            {name: 'wsphone', type: 'text', required: true},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'idrelay', type: 'text', required: true},
            {name: 'phone', type: 'text', required: true},
            {name: 'destphone', type: 'text', required: true},
            {name: 'wsphone', type: 'text', required: true},
            {name: 'station', hidden: true},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'idrelay', type: 'text', required: true},
            {name: 'phone', type: 'text', required: true},
            {name: 'destphone', type: 'text', required: true},
            {name: 'wsphone', type: 'text', required: true},
        ]
    },
    toString: object => {return object.name}},
    {name: 'fleet', menu: true, fields: {
        'id': {create: false, update: false},
        'name': {create: true, update: true},
        'customer': {create: false, update: false, object:'customer'},
        'vehicles': {create: true, update: true, array: true, child: true, object:'vehicle'},
        'vehicleSharing': {create: true, update: true},
        'vehicleSharingAutomatique': {create: true, update: true},
        'defaultVehicleSharing': {create: true, update: true, object:'vehicleSharing'},
        'vehicleSharings': {create: true, update: true, array: true, child: true, object:'vehicleSharing'},
        'drivers': {create: true, update: true, array: true, child: true, object:'driver'},
        'badges': {create: true, update: true, array: true, child: true, object:'badge'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, parentField: 'customer', list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'vehicles', type: 'list'},
            {name: 'vehicleSharings', type: 'list'},
            {name: 'drivers', type: 'list'},
            {name: 'badges', type: 'list'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'customer', hidden: true},
            {name: 'vehicleSharing', type: 'switch', required: true},
            {name: 'vehicleSharingAutomatique', type: 'switch', required: true},
            {name: 'defaultVehicleSharing', type: 'choice', sourcetype: 'object', source:'vehicleSharing'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'vehicleSharing', type: 'switch', required: true},
            {name: 'vehicleSharingAutomatique', type: 'switch', required: true},
            {name: 'defaultVehicleSharing', type: 'choice', sourcetype: 'object', source:'vehicleSharing'},
        ]
    },
    toString: object => {return object.name}},
    {name: 'vehicle', menu: true, fields: {
        'id': {create: false, update: false},
        'fleet': {create: true, update: true, object: 'fleet'},
        'vin': {create: false, update: false},
        'immatriculation': {create: true, update: true},
        'name': {create: true, update: true},
        'modele': {create: true, update: true, object: 'vehicleModele'},
        'sharing': {create: true, update: true, object: 'vehicleSharing', filter: [{
            field: 'fleet',
            path: 'fleet.id'
        }]},
        'devices': {create: true, update: true, array: true, child: true, object:'device'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, parentField: 'fleet', list: {
        title: 'Vehicules',
        fields: ['vin', 'immatriculation', 'name', 'modele']
    }, view: {
        fields: [
            {name: 'vin', type: 'text', required: true},
            {name: 'immatriculation', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'choice', sourcetype: 'object', source:'vehicleModele', required: true},
            {name: 'sharing', type: 'choice', sourcetype: 'object', source:'vehicleSharing', required: true},
            {name: 'devices', type: 'list'},
        ]
    }, creation: {
        fields: [
            {name: 'fleet', hidden: true},
            {name: 'vin', type: 'text', required: true},
            {name: 'immatriculation', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'choice', sourcetype: 'object', source:'vehicleModele', required: true},
            {name: 'sharing', type: 'choice', sourcetype: 'object', source:'vehicleSharing', required: true},
        ]
    }, edition: {
        fields: [
            {name: 'vin', type: 'text', required: true},
            {name: 'immatriculation', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'choice', sourcetype: 'object', source:'vehicleModele', required: true},
            {name: 'sharing', type: 'choice', sourcetype: 'object', source:'vehicleSharing', required: true},
        ]
    }},
    {name: 'vehicleType', menu: true, fields: {
        'id': {create: false, update: false},
        'name': {create: true, update: true},
        'mainEnergyType': {object:'energyType'},
        'alternativeEnergyType': {object:'energyType', array: true},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'mainEnergyType', type: 'choice', sourcetype: 'object', source:'energyType'},
            {name: 'alternativeEnergyType', type: 'choicelist', sourcetype: 'object', source:'energyType'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'mainEnergyType', type: 'choice', sourcetype: 'object', source:'energyType'},
            {name: 'alternativeEnergyType', type: 'choicelist', sourcetype: 'object', source:'energyType'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'mainEnergyType', type: 'choice', sourcetype: 'object', source:'energyType'},
            {name: 'alternativeEnergyType', type: 'choicelist', sourcetype: 'object', source:'energyType'},
        ]
    },
    toString: object => {return object.brand +' '+ object.name}},
    {name: 'vehicleModele', menu: true, fields: {
        'id': {create: false, update: false},
        'key': {create: true, update: true},
        'name': {create: true, update: true},
        'brand': {create: true, update: true},
        'img': {create: true, update: true},
        'tankVolume': {create: true, update: true},
        'type': {object:'vehicleType'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['name', 'brand']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'brand', type: 'text', required: true},
            {name: 'key', type: 'text', required: true},
            {name: 'type', type: 'choice', sourcetype: 'object', source:'vehicleType'},
            {name: 'tankVolume', type: 'text', required: true},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'brand', type: 'text', required: true},
            {name: 'key', type: 'text', required: true},
            {name: 'type', type: 'choice', sourcetype: 'object', source:'vehicleType'},
            {name: 'tankVolume', type: 'text', required: true},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'brand', type: 'text', required: true},
            {name: 'key', type: 'text', required: true},
            {name: 'img', type: 'file', required: true},
            {name: 'type', type: 'choice', sourcetype: 'object', source:'vehicleType'},
            {name: 'tankVolume', type: 'text', required: true},
        ]
    },
    toString: object => {return object.brand +' '+ object.name}},
    {name: 'vehicleSharing', menu: true, fields: {
        'id': {create: false, update: false},
        'fleet': {create: true, update: true, object: 'fleet'},
        'name': {create: true, update: true},
        'vehicles': {create: true, update: true, object: 'vehicle', array: true, filter: [{
            field: 'fleet',
            path: 'fleet.id'
        }]},        
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, parentField: 'fleet', list: {
        fields: ['name']
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'vehicles', type: 'choicelist', sourcetype: 'object', source:'vehicle'},
        ]
    }, creation: {
        fields: [
            {name: 'fleet', hidden: true},
            {name: 'name', type: 'text', required: true},
            {name: 'vehicles', type: 'choicelist', sourcetype: 'object', source:'vehicle'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'vehicles', type: 'choicelist', sourcetype: 'object', source:'vehicle'},
        ]
    }},
    {
        name: 'driver',
        schema: 'Driver',
        menu: true,
        fields: {
        'id': {create: false, update: false},
        'fleet': {create: true, update: true, object: 'fleet'},
        'firstName': {create: true, update: true},
        'lastName': {create: true, update: true},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false},
        'peerings': {create: false, update: false, object: 'peering', array: true},
        'vehicles': {create: true, update: true, object: 'vehicle', array: true, filter: [{
            field: 'fleet',
            path: 'fleet.id'
        }]},
        'sharings': {create: true, update: true, object: 'vehicleSharing', array: true, filter: [{
            field: 'fleet',
            path: 'fleet.id'
        }]},
        'devices': {create: true, update: true, array: true, child: true, object:'device'},
        'badges': {create: true, update: true, array: true, child: true, object:'badge'},
    }, toString: object => {return object.firstName +' '+ object.lastName}, parentField: 'fleet', list: {
        fields: ['firstName', 'lastName']
    }, view: {
        fields: [
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'devices', type: 'list'},
            {name: 'badges', type: 'list'},
            {name: 'vehicles', type: 'choicelist', sourcetype: 'object', source:'vehicle'},
            {name: 'sharings', type: 'choicelist', sourcetype: 'object', source:'vehicleSharing'},
        ]
    }, creation: {
        fields: [
            {name: 'fleet', hidden: true},
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'devices', type: 'choicelist', sourcetype: 'object', source:'device', canCreate: true, embedded: true},
            {name: 'badges', type: 'choicelist', sourcetype: 'object', source:'badge', canCreate: true, embedded: true},
            {name: 'vehicles', type: 'choicelist', sourcetype: 'object', source:'vehicle'},
            {name: 'sharings', type: 'choicelist', sourcetype: 'object', source:'vehicleSharing'},
        ]
    }, edition: {
        fields: [
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'devices', type: 'choicelist', sourcetype: 'object', source:'device', canCreate: true, embedded: true},
            {name: 'badges', type: 'choicelist', sourcetype: 'object', source:'badge', canCreate: true, embedded: true},
            {name: 'vehicles', type: 'choicelist', sourcetype: 'object', source:'vehicle'},
            {name: 'sharings', type: 'choicelist', sourcetype: 'object', source:'vehicleSharing'},
        ]
    }, createObject: function(newItem) {
        newItem.devices = [{fleet: newItem.fleet, phone: ''}];
        return newItem;
    }},
    {name: 'device', menu: true, fields: {
        'id': {create: false, update: false},
        'fleet': {create: true, update: true, object: 'fleet'},
        'driver': {create: true, update: true, object: 'driver'},
        'vehicle': {create: true, update: true, object: 'vehicle'},
        'phone': {create: true, update: true, structure: {
                countryCode: {},
                number: {},
            },
            _toString: (o) => o.number
        },
        'manufacturer': {create: true, update: true},
        'model': {create: true, update: true},
        'os': {create: true, update: true},
        'version': {create: true, update: true},
        'infos': {computed : o => { let infos = ''; if(o.manufacturer) {infos = o.manufacturer;} if(o.model) {infos = infos+' '+ o.model;} if(o.os) {infos = infos+' '+ o.os;} if(o.version) {infos = infos+' '+ o.version;} return infos; }},
        'peeringState' : { object: 'peering', computed: o => { return o.peerings && o.peerings.length > 0 ? o.peerings[o.peerings.length - 1] : null}},        
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, parentField: 'driver',
    list: {
        fields: ['phone', 'peeringState', 'infos']
    }, view: {
        fields: [
            {name: 'phone', type: 'structure', required: true, fields: [
                {name: 'countryCode', type: 'text', required: true},
                {name: 'number', type: 'text', required: true}
            ]},
            {name: 'driver', hideIfEmpty: true},
            {name: 'peeringState', type: 'text'},
            {name: 'infos', type: 'text'},
        ]
    }, creation: {
        fields: [
            {name: 'driver', hidden: true},
            {name: 'vehicle', hidden: true},
            {name: 'fleet', hidden: true},
            {name: 'phone', type: 'structure', required: true, fields: [
                {name: 'countryCode', type: 'text', required: true},
                {name: 'number', type: 'text', required: true}
            ]},
        ]
    }, edition: {
        fields: [
            {name: 'phone', type: 'structure', required: true, fields: [
                {name: 'countryCode', type: 'text', required: true},
                {name: 'number', type: 'text', required: true}
            ]}
        ]
    }, createObject: function(newItem) {
        newItem.phone = '';
        return newItem;
    }},
    {name: 'peering', schema: 'Peering', fields: {
        'id': {create: false, update: false},
        'token': {create: false, update: false, type: 'structure'},
        'keyId': {create: false, update: false},
        'state': {create: false, update: false},
        //'auth': {create: false, update: false},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, list: {
        fields: ['firstName', 'lastName', 'email', 'phone']
    }, toString: o => {
        if (o.state === 0) {
            const expiration = moment(o.token.expire);
            const expire = moment().isAfter(expiration);
            if (expire) {
                return 'Token expiré';
            } else {
                return 'Token ' + o.token.value + ' en attente (valide jusqu\'au ' + expiration.format('dddd DD MM YYYY [à] HH:mm:ss')+')';
            }
        }
        return o.token.value;
    }},
    {name: 'badge', menu: true, fields: {
        'id': {create: false, update: false},
        'fleet': {create: true, update: true, object: 'fleet'},
        'driver': {create: true, update: true, object: 'driver'},
        'badgeId': {create: true, update: true},
        'name': {create: true, update: true},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    }, parentField: 'driver',
    list: {
        fields: ['badgeId', 'name', 'driver']
    }, view: {
        fields: [
            {name: 'badgeId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'driver', hideIfEmpty: true},
        ]
    }, creation: {
        fields: [
            {name: 'driver', hidden: true},
            {name: 'badgeId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
        ]
    }, edition: {
        fields: [
            {name: 'driver', hidden: true},
            {name: 'badgeId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
        ]
    }, createObject: function(newItem) {
        newItem.phone = '';
        return newItem;
    }},
    {name: 'refill', menu: true, fields: {
        'id': {},
        'start': {type: 'date'},
        'end': {type: 'date'},
        'quantity': {},
        'state': {computed: (o, t) => {
            let more = '';
            if (o.state === 'ended') {
                if (o.endStatus) {
                    more = t('refill.endStatus.'+o.endStatus, '-');
                    more = ' (' + more + ')';
                }
                if (o.endStatus === 8 && o.endSubStatus) {
                    more = t('refill.endSubStatus.'+o.endSubStatus, '-');
                    more = ' (' + more + ')';
                }
            }
            return t('refill.state.'+o.state, '-') + more;
        }},
        'stationState': {},
        'serviceProvider': {object:'serviceProvider'},
        'customer': {object:'customer'},
        'vehicle': {object:'vehicle'},
        'driver': {object:'driver'},
        'station': {object:'station'},
        'dispenser': {object:'dispenser'},
        'authorization': {object:'authorization'},
        'energy': {object:'energy'},
        'energyType': {object:'energyType'},
        'createdAt': {},
        'updatedAt': {}
    }, list: {
        fields: ['customer', 'state', 'driver', 'station', 'start', 'end', 'quantity', 'energyType', 'authorization']
    },view: {
        fields: [
            {name: 'state', type: 'text', required: true},
            {name: 'stationState', type: 'text'},
            {name: 'start', type: 'text'},
            {name: 'end', type: 'text'},
            {name: 'quantity', type: 'text'},
            {name: 'energyType', type: 'text'},
        ]
    },
    toString: object => {return object.quantity}},
    {name: 'authorization', menu: false, fields: {
        'id': {},
        'enabled': {},
        'createdAt': {},
        'updatedAt': {}
    },view: {
        fields: [
            {name: 'id'},
            {name: 'enabled'}
        ]
    },
    toString: object => {return object.id}},
    {name: 'notificationSubscription', menu: true, fields: {
        'id': {create: false, update: false},
        'title': {},
        'type': {},
        'receivers': {},
        'ccReceivers': {},
        'bccReceivers': {},
        'stationOnlineEvent': {create: true, update: true, type: 'boolean'},
        'stationWfconnectorOnlineEvent': {create: true, update: true, type: 'boolean'},
        'stationPlcOnlineEvent': {create: true, update: true, type: 'boolean'},
        'stationPublicStateEvent': {create: true, update: true, type: 'boolean'},
        'energyPublicStateEvent': {create: true, update: true, type: 'boolean'}, 
		'serviceProvider': {object:'serviceProvider'},
        'enabled': {create: false, update: false, type: 'boolean'},
        'createdAt': {create: false, update: false},
        'updatedAt': {create: false, update: false}
    },
    list: {
        fields: ['title', 'stationOnlineEvent', 'stationWfconnectorOnlineEvent', 'stationPublicStateEvent', 'enabled']
    }, view: {
        fields: [
            {name: 'type', type: 'choice', sourcetype: 'value', source: {0: 'email', 1: 'push'}},
            {name: 'receivers', type: 'text', required: true},
            {name: 'ccReceivers', type: 'text'},
            {name: 'bccReceivers', type: 'text'},
            {name: 'stationOnlineEvent'},
            {name: 'stationWfconnectorOnlineEvent'},
            {name: 'stationPlcOnlineEvent'},
            {name: 'stationPublicStateEvent'},
            {name: 'energyPublicStateEvent'},
            {name: 'enabled'},
			{name: 'serviceProvider'},
        ]
    }, creation: {
        fields: [
            {name: 'title'},
            {name: 'type', type: 'choice', sourcetype: 'value', source: {0: 'email', 1: 'push'}},
            {name: 'receivers', type: 'text', required: true},
            {name: 'ccReceivers', type: 'text'},
            {name: 'bccReceivers', type: 'text'},
            {name: 'stationOnlineEvent'},
            {name: 'stationWfconnectorOnlineEvent'},
            {name: 'stationPlcOnlineEvent'},
            {name: 'stationPublicStateEvent'},
            {name: 'energyPublicStateEvent'},
			{name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'}
        ]
    }, edition: {
        fields: [
            {name: 'title'},
            {name: 'type', type: 'choice', sourcetype: 'value', source: {0: 'email', 1: 'push'}},
            {name: 'receivers', type: 'text', required: true},
            {name: 'ccReceivers', type: 'text'},
            {name: 'bccReceivers', type: 'text'},
            {name: 'stationOnlineEvent'},
            {name: 'stationWfconnectorOnlineEvent'},
            {name: 'stationPlcOnlineEvent'},
            {name: 'stationPublicStateEvent'},
            {name: 'energyPublicStateEvent'},
            {name: 'enabled'},
			{name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'}
        ]
    }},
    {name: 'outputField', menu: true, fields: {
        'id': {create: false, update: false},
        'name': {},
        'description': {},
        'outputName': {},
        'format': {},
        'outputFields': {object:'outputField', create: true, update: true, array: true, child: true},
        'order': {},
        'enabled': {type: 'boolean'},
        'createdAt': {},
        'updatedAt': {}
    },
    list: {
        fields: ['name', 'description', 'order']
    }, view: {
        fields: [
            {name: 'name'},
            {name: 'description'},
            {name: 'outputName'},
            {name: 'format'},
            {name: 'outputFields', type: 'list'},
            {name: 'order'},
        ]
    }, creation: {
        fields: [
            {name: 'name'},
            {name: 'description'},
            {name: 'outputName'},
            {name: 'format'},
            {name: 'outputFields', type: 'ownlist', sourcetype: 'object', source:'outputField'},
            {name: 'order'},
        ]
    }, edition: {
        fields: [
            {name: 'name'},
            {name: 'description'},
            {name: 'outputName'},
            {name: 'format'},
            {name: 'outputFields', type: 'ownlist', sourcetype: 'object', source:'outputField'},
            {name: 'order'},
        ]
    }},
    {name: 'dataExport', menu: true, fields: {
        'id': {create: false, update: false},
        'name': {},
        'description': {},
        'object': {object:'object'},
        'outputFields': {object:'outputField', create: true, update: true, array: true, child: true},
        'serviceProvider': {object:'serviceProvider'},
        'customer': {object:'customer'},
        'features': {object:'feature', array: true},
        'enabled': {type: 'boolean'},
        'createdAt': {},
        'updatedAt': {}
    },
    list: {
        fields: ['name', 'description', 'serviceProvider', 'customer', 'enabled']
    }, view: {
        fields: [
            {name: 'name'},
            {name: 'description'},
            {name: 'serviceProvider'},
            {name: 'customer'},
            {name: 'features', type: 'list'},
            {name: 'object'},
            {name: 'outputFields', type: 'list'},
            {name: 'enabled'},
        ]
    }, creation: {
        fields: [
            {name: 'name'},
            {name: 'description'},
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'customer', type: 'choice', sourcetype: 'object', source:'customer'},
            {name: 'features', type: 'choicelist', sourcetype: 'object', source:'feature'},
            {name: 'object', type: 'choice', sourcetype: 'object', source:'object'},
            {name: 'outputFields', type: 'ownlist', sourcetype: 'object', source:'outputField'},
            {name: 'enabled'},
        ]
    }, edition: {
        fields: [
            {name: 'name'},
            {name: 'description'},
            {name: 'features', type: 'choicelist', sourcetype: 'object', source:'feature'},
            {name: 'object', type: 'choice', sourcetype: 'object', source:'object'},
            {name: 'outputFields', type: 'ownlist', sourcetype: 'object', source:'outputField'},
            {name: 'enabled'},
        ]
    }},
    {name: 'jobExport', menu: true, fields: {
        'id': {create: false, update: false},
        'name': {},
        'type': {},
        'frequency': {},
        'hour': {},
        'weekDay': {},
        'monthDay': {},
        'receivers': {},
        'ccReceivers': {},
        'bccReceivers': {},
        'lastRun': {type: 'date'},
        'dataExport': {object:'dataExport'},
        'serviceProvider': {object:'serviceProvider'},
        'customer': {object:'customer'},
        'enabled': {type: 'boolean'},
        'createdAt': {},
        'updatedAt': {}
    },
    list: {
        fields: ['name', 'frequency', 'lastRun', 'serviceProvider', 'customer', 'enabled']
    }, view: {
        fields: [
            {name: 'name'},
            {name: 'dataExport'},
            {name: 'frequency', type: 'choice', sourcetype: 'value', source: {1: 'daily', 2: 'weekly', 3: 'monthly'}},
            {name: 'hour'},
            {name: 'weekDay'},
            {name: 'monthDay'},
            {name: 'type', type: 'choice', sourcetype: 'value', source: {0: 'email'}},
            {name: 'receivers', type: 'text', required: true},
            {name: 'ccReceivers', type: 'text'},
            {name: 'bccReceivers', type: 'text'},
            {name: 'serviceProvider'},
            {name: 'lastRun'},
            {name: 'customer'},
            {name: 'enabled'},
        ]
    }, creation: {
        fields: [
            {name: 'name'},
            {name: 'dataExport', type: 'choice', sourcetype: 'object', source:'dataExport'},
            {name: 'hour'},
            {name: 'weekDay'},
            {name: 'monthDay'},
            {name: 'frequency', type: 'choice', sourcetype: 'value', source: {1: 'daily', 2: 'weekly', 3: 'monthly'}},
            {name: 'type', type: 'choice', sourcetype: 'value', source: {0: 'email'}},
            {name: 'receivers', type: 'text', required: true},
            {name: 'ccReceivers', type: 'text'},
            {name: 'bccReceivers', type: 'text'},
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'customer', type: 'choice', sourcetype: 'object', source:'customer'},
        ]
    }, edition: {
        fields: [
            {name: 'name'},
            {name: 'dataExport', type: 'choice', sourcetype: 'object', source:'dataExport'},
            {name: 'hour'},
            {name: 'weekDay'},
            {name: 'monthDay'},
            {name: 'frequency', type: 'choice', sourcetype: 'value', source: {1: 'daily', 2: 'weekly', 3: 'monthly'}},
            {name: 'type', type: 'choice', sourcetype: 'value', source: {0: 'email'}},
            {name: 'receivers', type: 'text', required: true},
            {name: 'ccReceivers', type: 'text'},
            {name: 'bccReceivers', type: 'text'},
            {name: 'enabled'},
        ]
    }},  
	{name: 'clientApp', menu: true , fields: {
        'id': {create: false, update: false},
        'appIdApple': {create: true, update: false, required: true},
		'lastVersionApple': {create: true, update: false, required: true},
		'minimalVersionApple': {create: true, update: false, required: true},
		'storeLinkApple': {create: true, update: false, required: true},
		
		'appIdAndroid': {create: true, update: false, required: true},
		'lastVersionAndroid': {create: true, update: false, required: true},
		'minimalVersionAndroid': {create: true, update: false, required: true},
		'storeLinkAndroid': {create: true, update: false, required: true},
		
		'configCode': {create: true, update: false, required: true},
		'appName': {create: true, update: false, required: true},
		'description': {create: true, update: false, required: true},
		
		'admin': {object:'User'}
    }, list: {
        fields: [
			'appName', 'description'
		]
    }, view: {
        fields: [
			{name: 'appIdApple'}, 
			{name: 'lastVersionApple'}, 
			{name: 'minimalVersionApple'}, 
			{name: 'storeLinkApple'},
			{name: 'appIdAndroid'}, 
			{name: 'lastVersionAndroid'}, 
			{name: 'minimalVersionAndroid'}, 
			{name: 'storeLinkAndroid'},
			{name: 'configCode'}, 
			{name: 'appName'}, 
			{name: 'description'}
		]
    }, creation: {
        fields: [
			{name: 'appIdApple'}, 
			{name: 'lastVersionApple'}, 
			{name: 'minimalVersionApple'}, 
			{name: 'storeLinkApple'},
			{name: 'appIdAndroid'}, 
			{name: 'lastVersionAndroid'}, 
			{name: 'minimalVersionAndroid'}, 
			{name: 'storeLinkAndroid'},
			{name: 'configCode'}, 
			{name: 'appName'}, 
			{name: 'description'}
		]
    }, edition: {
        fields: [
			{name: 'appIdApple'}, 
			{name: 'lastVersionApple'}, 
			{name: 'minimalVersionApple'}, 
			{name: 'storeLinkApple'},
			{name: 'appIdAndroid'}, 
			{name: 'lastVersionAndroid'}, 
			{name: 'minimalVersionAndroid'}, 
			{name: 'storeLinkAndroid'},
			{name: 'configCode'}, 
			{name: 'appName'}, 
			{name: 'description'}
		]
    }},  
	{name: 'apiClient', menu: true, fields: {
        'id': {create: false, update: false},
        'keyName': {create: true, update: false, required: true},
		'keyDate': {create: true, update: false, required: true},
		'publicKey': {create: true, update: false, required: true},
		'serviceProvider': {object:'serviceProvider'},
		'customer': {object:'customer'}
    }, list: {
        fields: [
			'serviceProvider', 'keyName', 'keyDate'
		]
    }, view: {
        fields: [
			{name: 'keyName'}, 
			{name: 'keyDate'}, 
			{name: 'publicKey'}
		]
    }, creation: {
        fields: [
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider' },
			{name: 'customer', type: 'choice', sourcetype: 'object', source:'customer' }
		]
    }, edition: {
        fields: [
			{name: 'keyName'},
		]
    }},
	{name: 'operatorContract', menu: true, fields: {
        'id': {create: false, update: false},
        'operator': {required: true, object:'operator'},
		'serviceProvider': {required: true, object:'serviceProvider'},
		'stationNetworks': {required: false, array: true, object:'stationNetwork'},
		'status': {},
        'start': {},
        'end': {}
    }, list: {
        fields: [
			'operator', 'serviceProvider', 'start', 'end', 'status'
		]
    }, view: {
        fields: [
			{name: 'operator'}, 
			{name: 'serviceProvider'}, 
            {name: 'start'},
            {name: 'end'},
            {name: 'stationNetworks', type: 'list'},
            { name: 'status' }
		]
    }, creation: {
        fields: [
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider' },
            {name: 'operator', type: 'choice', sourcetype: 'object', source:'operator' },
            {name: 'start', type: 'date'},
            {name: 'end', type: 'date'},
            {name: 'stationNetworks', type: 'choicelist', sourcetype: 'object', source:'stationNetwork' }
		]
    }, edition: {
        fields: [
            {name: 'status', type: 'number'},
            {name: 'start', type: 'date'},
            {name: 'end', type: 'date'},
            {name: 'stationNetworks', type: 'choicelist', sourcetype: 'object', source:'stationNetwork' }
		]
    }},
	{name: 'stationNetwork', menu: true, fields: {
        'id': {create: false, update: false},
        'name': {},
        'operator': {required: true, object:'operator'},
		'stations': {required: true, array: true, object:'station'}
    }, list: {
        fields: [
			'name', 'operator'
		]
    }, view: {
        fields: [
			{name: 'name'}, 
			{name: 'operator'}, 
            {name: 'stations', type: 'list'}
		]
    }, creation: {
        fields: [
            {name: 'name'}, 
			{name: 'operator', type: 'choice', sourcetype: 'object', source:'operator' },
            {name: 'stations', type: 'choicelist', sourcetype: 'object', source:'station' }
		]
    }, edition: {
        fields: [
            {name: 'name'}, 
            {name: 'stations', type: 'choicelist', sourcetype: 'object', source:'station' }
		]
    },
    toString: object => {return object.name}}
];

export const ObjectsMap = {};

Objects.forEach( model => {
    ObjectsMap[model.name] = model;
}
);