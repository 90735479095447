import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import Divider from '@material-ui/core/Divider';
import ObjectInfoLine from '../../../_components/ObjectInfoLine';
import { ChoiceList } from '../ChoiceList';
import { commonActions } from '../../../_actions';
import EnhancedTable from '../../../_components/EnhancedTable';
import { Objects, ObjectsMap } from '../../../_config';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem',
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
      listlightcontainer: {
        backgroundColor: theme.palette.background.paper,
      }
});

class ReadObject extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

      getData() {
        let { scope, data } = this.props;

        if (data && scope) {
            const scopedData = data[scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }

    getItem() {
        const data = this.getData();
        return data.item;
    }

    getSource(fieldName) {
        const data = this.getData();
        
        const source = data && data.sources && data.sources[fieldName];

        return source;
    }

    makeInputFields = (fields, object, classes, path) => {
        const { t } = this.props;
        const inputFields = [];
        if (object) {
            fields.forEach((element, index) => {

                let fieldValue;
                const fieldConfig = this.props.model ? this.props.model.fields[element.name] : {};
                const computed = fieldConfig.computed;
                if (computed) {
                    fieldValue = object ? computed(object) : null;
                } else {
                    fieldValue = object ? object[element.name] : null;
                }

                if (fieldValue || !element.hideIfEmpty) {

                
                if (element.type === 'structure') {
                    let structValue = fieldValue ? fieldValue : {};
                    structValue = structValue ? structValue : {};
                    inputFields.push(
                        (<div key={"form_struct_" +index}>

                                <Typography>{element.name}</Typography>
                                <ConnectedReadObject
                                    classes={classes}
                                    fields={element.fields}
                                    object={structValue}
                                />
                                <div>
                                   
                                </div>
                            </div>
                        )
                    );

                } else if (element.type === 'listlight') {

                    const selectedItems = fieldValue ? fieldValue : [];
                    
                    const listItems = selectedItems.map( (item, index) => (
                        <div key={'listlight_' + index}>
                            {index > 0 ? <Divider /> : (null)}
                            <ListItem>
                                <ListItemText primary={item.name} />
                            </ListItem>
                        </div>
                    ));
                    
                    inputFields.push(
                        (<div key={"form_list-light_" +index} className={classes.listlightcontainer}>
                        <List subheader={<ListSubheader>{element.name}</ListSubheader>}>
                            {listItems}
                        </List>
                        </div>)
                    );
                } else if (element.type === 'list') {
                    const items = fieldValue;
                    
                        const config = ObjectsMap[fieldConfig.object];
                        
                        const columns = config.list.fields.map(field => { return {id: field, numeric: false, disablePadding: true, label: field }});
                        inputFields.push(
                        <div key={"form_ownlist_" +index}>
                            <Typography>{element.name}</Typography>
                            <EnhancedTable
                                model={config}
                                columns={columns}
                                rows={items ? items : []}
                                tableTitle={element.name}
                                addAction={() => {this.handleAddChild(element.name, config)}}
                                readAction={(item) => {this.handleReadChild(element.name, config, item)}}
                                editAction={(item) => {this.handleEditChild(element.name, config, item)}}
                                withPagination={true}
                            />
                        </div>
                        );
                    
                } else {

                    let displayedValue = fieldValue;

                    const fieldDef = this.props.model ? fieldConfig : null;
                    
                    if (fieldDef && fieldDef.object) {
                        const config = ObjectsMap[fieldDef.object];
                        displayedValue = displayedValue ? (config.toString ? config.toString(displayedValue) :displayedValue) : '';
                    } else if (fieldConfig && fieldConfig.type === 'boolean') {
                        displayedValue = t((fieldValue === true || fieldValue === 1 ? 'yes':'no'));
                    }

                    inputFields.push(
                        (<FormControl key={"form_input_" +index} className={classes.formControl}>
                                <InputLabel htmlFor="component-simple">{element.name}</InputLabel>
                                <Input id="component-simple" readOnly={true} value={displayedValue} />
                            </FormControl>
                        )
                    );

                }

                }

            });
        }
        return inputFields;

      }

      handleEditChild = (fieldName, config, object) => {
        this.props.dispatch(commonActions.goEdit(config, object, this.props.object));
      }

      handleReadChild = (fieldName, config, object) => {
        this.props.dispatch(commonActions.goRead(config, object, this.props.object));
      }

      handleAddChild = (fieldName, config) => {
        this.props.dispatch(commonActions.goCreate(config, this.props.object));
      }


    render() {
        const { classes, embedded, object, fields, model } = this.props;
        
        const formFields = this.makeInputFields(fields, object, classes);

        return formFields;
    }
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    
    return {
        itemId,
        data
    };
}

const ConnectedReadObject = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ReadObject)));
export { ConnectedReadObject as ReadObject };