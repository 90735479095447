import React, { Component } from 'react';
import { translate } from 'react-i18next';

import TextField from '@material-ui/core/TextField';

class DatePicker extends Component {

    render() {

     const { t, id, label, object, value, onChange, required, disabled } = this.props;
	 
       return (
        <TextField
        id={id}
        label={t(label)}
        type="date"
        value={value}
        required={required}
        disabled={disabled}
        onChange={(e) => {onChange(e)}}
        InputLabelProps={{
          shrink: true,
        }}
      />
        )
    }
    
}

const connectedDatePicker = translate('translations')(DatePicker);
export { connectedDatePicker as DatePicker };