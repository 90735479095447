import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';

import AccountCircle from '@material-ui/icons/AccountCircle';

import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';

import { commonActions } from '../../_actions';

import EnhancedTable from '../../_components/EnhancedTable';
import Breadcrumb from '../../_components/Breadcrumb';
import { getRights } from '../../_helpers';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem'
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
      partTitle: {
          marginTop: '2rem',
          marginBottom: '2rem',
      }
});

class ProfilTOTP extends React.Component {

    constructor(props) {
        super(props)
        this.state = {expanded: false};
        this.model = this.props.model;
        this.rights = getRights(this.props.user, 'main', this.model.name);

        const list = this.props.model.list;
        
        const fieldsConfig = this.model.fields;

        this.columns = list.fields.map(field => {
            const label = fieldsConfig[field].displayName ? fieldsConfig[field].displayName : field;
            return {id: field, numeric: false, label: label }
        });
    }

    handleEditAction = (item) => {
        this.props.history.push('/profil/totp/edit/' + item.id);
    }

    handleAddAction = () => {
        this.props.history.push('/profil/totp/create');
    }

    componentWillMount() {
        const { t, user, model } = this.props;
        const breadcrumb = [];
        breadcrumb.push({icon: (<AccountCircle/>), name: 'Profil de ' + user.user.firstName +' '+user.user.lastName, url:'/profil'})
        breadcrumb.push({icon: (<MobileFriendlyIcon/>), name: 'Application mobile'})
        this.breadcrumb = breadcrumb;

        this.props.dispatch(commonActions.getAll('main', model, undefined, undefined));
    }

    componentWillUnmount() {
        
    }

    
    render() {
        const { t, user, items, classes } = this.props;

        const breadcrumb = this.breadcrumb;

        return (
            <div>
                <Breadcrumb elements={breadcrumb}/>

                {
                    items &&
                    <EnhancedTable
                        scope={this.scope}
                        model={this.model}
                        columns={this.columns}
                        rows={items}
                        tableTitle={this.model.name}
                        addAction={this.rights.create ? this.handleAddAction : null}
                        readAction={this.rights.read ? this.handleReadAction : null}
                        editAction={this.rights.edit ? this.handleEditAction : null}
                        deleteAction={this.rights.delete ? this.handleDeleteAction: null}
                        doubleClickAction={this.rights.edit ? this.handleEditAction : null}
                        withPagination={true}
                    />
                }

            </div>
        );
    }
}

function mapStateToProps(state) {
    const { data, authentication } = state;
    
    const { user, context} = authentication;

    return {
        data,
        items: data.main ? data.main.items : null,
        user,
        context
    };
}

const connectedProfilTOTP = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ProfilTOTP)));
export { connectedProfilTOTP as ProfilTOTP };