import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
 
i18n
  .use(LanguageDetector)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "product.name": "NEXOPUS",
          "context.admin": "ADMINISTRATION",
          "context.manager": "MANAGER",
          "home.title": "Home",
          "yes": "Yes",
          "no": "No",
          "none": "None",
          "context": "Context",
          "validated": "Validated",
          "firstName": "First name",
          "lastName": "Last name",
          "user": "User",
          "users": "Users",
          "operator": "Operator",
          "serviceProvider": "Service provider",
          "driver": "Driver",
          "drivers": "Drivers",
          "device": "Device",
          "devices": "Devices",
          "energy": "Energy",
          "energys": "Energies",
          "delivered.energys": "Delivered energies",
          "energyType": "Energy Type",
          "energyTypes": "Energy Types",
          "contract": "Contract",
          "contractType": "Contract Type",
          "customer": "Customer",
          "customers": "Customers",
          "station": "Station",
          "stations": "Stations",
          "dispenser": "Dispenser",
          "dispensers": "Dispensers",
          "barrier": "Barrier",
          "barriers": "Barrieres",
          "refill": "Refill",
          "refills": "Refills",
          "fleet": "Fleet",
          "fleets": "Fleets",
          "vehicle": "Vehicle",
          "vehicles": "Vehicles",
          "vehicleModele": "Vehicle Model",
          "vehicleSharing": "Vehicle Sharing",
          "vehicleSharings": "Vehicle Sharings",
          "badge": "Badge",
          "badges": "Badges",
          "stationAvailability": "Station Availability Event",
          "stationAvailabilitys": "Station Availability Events",
          "created.on": "Created on",
          "last.update.on": "Last update on",
          "account.activation": "Activation",
          "name": "Name",
          "email": "Email address",
		  "push": "Push notification",
		  "start": "Contract start date",
		  "end": "Contract end date",
          "email.error.required": "Email address is required",
          "password": "Password",
          "password.error.required": "Password is required",
          "confirmPassword": "Password confirmation",
          "confirmPassword.error.required": "Password confirmation is required",
          "double.security": "Double security",
          "u2f.please.click.key.to.login": "Push the warning button on your security key",
          "u2f.sign.error": "Key checking has failed",
          "validaction.account.error": "This link is no longer valid",
          "u2f.compatibility.error": "Pour vous authentifier sur Nexopus, il est nécessaire que votre navigateur soit compatible avec les clés de sécurité U2F.",
          "twoauth.solution.U2F": "U2F Security key",
          "twoauth.solution.TOTP": "Mobile application",
          "totp.verify.error": "Token checking has failed",
          "account": "Account",
          "action.login": "Login",
          "action.validate": "Valider",
          "action.cancel": "Cancel",
          "action.next": "Next",
          "action.gologin": "Go to login page",
          "action.logout": "Logout",
          "action.add.new": "Add",
          "action.save": "Save",
          "action.choose": "Choose",
          "action.edit": "Edit",
          "action.open": "Open",
          "action.sendsmspeering": "Send SMS mobile app",
          "action.profil": "Manage my account",
          "form.error.login.not.equal.confirmpassword": "Password and password confirmation are not equals",
          "u2f.addkey.plugin": "Branchez votre clé U2F que vous souhaitez associer à votre compte et cliquez sur le bouton suivant.",
          "u2f.addkey.save": "Saving your key",
          "useraccess.validated": "Your account is ready, you can now login",
          "loading.context": "Loading your context...",
          "loading.data": "Loading data...",
          "menu.fleet": "Fleet",
          "menu.admin": "Administration",
          "menu.station": "Stations",
          "menu.customer": "Customers",
          "creation": "Creation",
          "edition": "Edition",
          "read": "Read",
          "phone": "Phone number",
          "enabled": "Enabled",
          "badgeId": "Badge number",
          "vin": "VIN",
          "immatriculation": "Registration",
          "modele": "Modele",
          "field.required": "Field required",
          "field.badFormat": "Bad value",
          "profil": "Profil",
          "profil.administrator": "Platorm administrator",
		  "profil.ADMIN": "Platorm administrator",
          "profil.service_manager": "Service manager",
		  "profil.SERVICE_MANAGER": "Service manager",
          "profil.fleet_manager": "Fleet manager",
		  "profil.FLEET_MANAGER": "Fleet manager",
          "role": "Role",
          "role.administrator": "Administrator",
          "role.manager": "Manager",
          "role.viewer": "Viewer",
          "address": "Address",
          "street": "Street",
          "zipcode": "Zip code",
          "city": "City",
          "country": "Country",
          "gps": "GPS",
          "latitude": "Latitude",
          "longitude": "Longitude",
          "connected": "Connected",
          "vehicleSharingAutomatique": "Automatique vehicle sharing",
          "defaultVehicleSharing": "Default share",
          "login.failed": "Login has failed, please check your login and password",
          "state": "State",
          "stationState": "Station state",
          "start": "Start",
          "end": "End",
          "duration": "Duration",
          "quantity": "Quantity",
          "steps": "Steps",
          "step": "Step",
          "barrier.destphone": "External phone",
          "idrelay": "Code relay",
          "barrier.wsphone": "Phone Input WS",
          "public": "Public",
          "label": "Label",
          'peeringState': "Mobile app state",
          "refill.state.waitinqueue": "in the waiting line",
          "refill.state.firstinqueue": "wait ot unlock",
          "refill.state.waitToStart": "wait to start",
          "refill.state.preparing": "preparing",
          "refill.state.inProgress": "in progress",
          "refill.state.ending": "ending",
          "refill.state.ended": "ended",
          "refill.state.cancelled": "cancelled",
          "refill.state.unlocktimeout": "not unlocked",
          "refill.state.starttimeout": "not started",
          "refill.state.inError": "error",
          "refill.endStatus.0": "-",
          "refill.endStatus.1": "Arrêt bouton local",
          "refill.endStatus.3": "Erreur L2 dispenser",
          "refill.endStatus.4": "Cible pression dispenser atteinte",
          "refill.endStatus.6": "Erreur station",
          "refill.endStatus.7": "Signal d'interruption",
          "refill.endStatus.8": "SAEJ",
          "refill.endStatus.9": "Signal d'arrêt",
          "refill.endStatus.10": "Arrêt POS",
          "refill.endStatus.11": "Arrêt recharge mode dégradé",
          "refill.endStatus.12": "Cible pression véhicule atteinte",
          "refill.endSubStatus.0": "-",
          "refill.endSubStatus.1": "Initial Pressure out of range",
          "refill.endSubStatus.2": "Volume tank out of range",
          "refill.endSubStatus.3": "Fallback not authorized",
          "refill.endSubStatus.4": "H2 temp above T30 table",
          "refill.endSubStatus.5": "Target Pressure reached with com",
          "refill.endSubStatus.7": "Target Pressure reached without com",
          "refill.endSubStatus.9": "Target Pressure top off reached with com",
          "refill.endSubStatus.10": "Wrong initial calculation",
          "rememberme": "Remember me",
          "stationstate-online": "disponible",
          "stationstate-wferror": "erreur liaison PLC",
          "added.on": "added on",
          "associated": "associated",
          "plc": "Informations PLC",
          "pos.version": "POS version",
          "wfconnector.version": "WF Connector version",
          "fuelingStatus": "Fueling status",
          "queue": "Queue",
          "nozzlePosition": "Nozzle position",
          "unlocked": "Unlocked",
          "state.open": "Open",
          "state.partial": "Partial",
          "state.busy": "Busy",
          "state.maintenance": "Maintenance",
          "state.breakdown": "Breakdown",
          "state.closed": "Closed",
          "state.empty": "Empty",
          "state.unknown": "unknown",
          "state.offline": "Offline",
          "publicState": "Public state",
          "lastping": "Last ping",
          "progress": "Progress",
          "filter.refill.date":"Refills date",
          "customer.status.closed": "Closed",
          "customer.status.created": "Created",
          "customer.status.ready": "Ready",
          "customer.status.locked": "Locked",
          "running": "running",
          "not.started": "not started",
          "success": "success",
          "error": "error",
          "nodata.todisplay": "no data to display",
          "filter.daily": "Daily",
          "filter.weekly": "Weekly",
          "filter.monthly": "Monthly",
          "filter.yearly": "Yearly",
          "filter.refill.pos": "POS",
          "periode.from": "du",
          "periode.to": "au",
          "pos.nexopus.app": "Nexopus",
          "pos.tokheim": "Tokheim",
          "userRole": "Role",
          "ADMIN": "Administrator",
          "MANAGER": "Manager",
          "VIEWER": "Viewer",
          "availability": "Availability"
        }
      },
      fr: {
        translations: {
          "product.name": "NEXOPUS",
          "context.admin": "ADMINISTRATION",
          "context.manager": "MANAGER",
          "home.title": "Accueil",
          "yes": "Oui",
          "no": "Non",
          "none": "Aucun",
          "context": "Context",
          "validated": "Validé",
          "firstName": "Prénom",
          "lastName": "Nom",
          "user": "Utilisateur",
          "users": "Utilisateurs",
          "operator": "Operateur",
          "serviceProvider": "Fournisseur de service",
          "driver": "Conducteur",
          "drivers": "Conducteurs",
          "device": "Téléphone",
          "devices": "Téléphones",
          "energy": "Energie",
          "energys": "Energies",
          "delivered.energys": "Energies délivrées",
          "energyType": "Type d'energie",
          "energyTypes": "Types d'energie",
          "contract": "Contrat",
          "contractType": "Type de contrat",
          "customer": "Client",
          "customers": "Clients",
          "station": "Station",
          "stations": "Stations",
          "dispenser": "Dispenser",
          "dispensers": "Dispensers",
          "barrier": "Barrière",
          "barriers": "Barrières",
          "refill": "Recharge",
          "refills": "Recharges",
          "fleet": "Flotte",
          "fleets": "Flottes",
          "vehicle": "Véhicule",
          "vehicles": "Véhicules",
          "vehicleModele": "Modèle de vehicule",
          "vehicleSharing": "Partage de véhicules",
          "vehicleSharings": "Partages de véhicules",
          "badge": "Badge",
          "badges": "Badges",
          "stationAvailability": "Changement de disponibilité",
          "stationAvailabilitys": "Changements de disponibilité",
          "created.on": "Créé le",
          "last.update.on": "Mis à jour le",
          "account.activation": "Activation",
          "name": "Nom",
          "email": "Adresse email",
          "email.error.required": "L'adresse email est manquante",
		  "push": "Notification push",
		  "start": "Date début contrat",
		  "end": "Date fin contrat",
          "password": "Mot de passe",
          "password.error.required": "Le mot de passe est manquant",
          "confirmPassword": "Confirmation du mot de passe",
          "confirmPassword.error.required": "La confirmation du mot de passe est manquante",
          "double.security": "Double sécurité",
          "u2f.please.click.key.to.login": "Appuyez sur le bouton clignotant de votre clé de sécurité",
          "u2f.sign.error": "La vérification de votre clé de sécurité à échouché",
          "validaction.account.error": "Ce lien de validation ne semble plus valide.",
          "u2f.compatibility.error": "Pour valider votre compte et vous authentifier sur Nexopus, il est nécessaire que votre navigateur soit compatible avec les clés de sécurité U2F.",
          "twoauth.solution.U2F": "Clé de sécurité U2F",
          "twoauth.solution.TOTP": "Application mobile",
          "totp.verify.error": "La vérification du code a échoué.",
          "account": "Compte",
          "action.login": "Login",
          "action.validate": "Valider",
          "action.cancel": "Annuler",
          "action.next": "Suivant",
          "action.gologin": "Aller à la page de connexion",
          "action.logout": "Déconnexion",
          "action.add.new": "Ajouter",
          "action.save": "Enregistrer",
          "action.choose": "Sélectionner",
          "action.edit": "Modifier",
          "action.open": "Ouvrir",
          "action.profil": "Gérer mon profil",
          "action.sendsmspeering": "Envoyer SMS App mobile",
          "form.error.login.not.equal.confirmpassword": "Le mot de passe et la confirmation du mot de passe ne sont pas égaux",
          "u2f.addkey.plugin": "Branchez la clé U2F que vous souhaitez associer à votre compte et cliquez sur le bouton suivant.",
          "u2f.addkey.save": "Enregistrement de votre clé",
          "useraccess.validated": "Votre compte est validé !",
          "loading.context": "Chargement de votre espace en cours...",
          "loading.data": "Chargement des données en cours...",
          "menu.fleet": "Flotte",
          "menu.admin": "Administration",
          "menu.station": "Stations",
          "menu.customer": "Clients",
          "creation": "Création",
          "edition": "Edition",
          "read": "Consultation",
          "phone": "Numéro de téléphone",
          "enabled": "Actif",
          "badgeId": "Numéro badge",
          "vin": "VIN",
          "immatriculation": "Immatriculation",
          "modele": "Modèle",
          "field.required": "Champ obligatoire",
          "field.badFormat": "Valeur incorrecte",
          "profil": "Profil",
          "profil.administrator": "Administrateur plateforme",
		  "profil.ADMIN": "Administrateur plateforme",
          "profil.service_provider": "Fournisseur de service",
		  "profil.SERVICE_PROVIDER": "Fournisseur de service",
          "profil.fleet_manager": "Gestionnaire de flotte",
          "profil.FLEET_MANAGER": "Gestionnaire de flotte",
          "role": "Role",
          "role.administrator": "Administrateur",
          "role.manager": "Manager",
          "role.viewer": "Consultant",
          "address": "Adresse",
          "street": "Rue",
          "zipcode": "Code postal",
          "city": "Ville",
          "country": "Pays",
          "gps": "Coordonnées GPS",
          "latitude": "Latitude",
          "longitude": "Longitude",
          "connected": "Connecté",
          "vehicleSharingAutomatique": "Gestion automatique du partage",
          "defaultVehicleSharing": "Partage par défaut",
          "login.failed": "L'authentification a échoué, veuillez vérifier votre identifiant et votre mot de passe.",
          "state": "Etat",
          "stationState": "Etat de la station",
          "start": "Début",
          "end": "Fin",
          "duration": "Durée",
          "quantity": "Quantité",
          "steps": "Etapes",
          "step": "Etape",
          "barrier.destphone": "Téléphone prestataire",
          "idrelay": "Code relay",
          "barrier.wsphone": "Téléphone entrée WS",
          "public": "Public",
          "label": "Libellé",
          'peeringState': "Application mobile",
          "app.peered": "Associé",
          "app.notpeered": "Non associé",
          "refill.state.waitinqueue": "en file d'attente",
          "refill.state.firstinqueue": "attente dévérouillage",
          "refill.state.waitToStart": "attente démarrage",
          "refill.state.preparing": "démarrage",
          "refill.state.inProgress": "en cours",
          "refill.state.ending": "arrêt",
          "refill.state.ended": "terminé",
          "refill.state.cancelled": "annulé",
          "refill.state.unlocktimeout": "Non dévérouillé à temps",
          "refill.state.starttimeout": "Non démarré à temps",
          "refill.state.inError": "erreur",
          "refill.endStatus.0": "-",
          "refill.endStatus.1": "Arrêt bouton local",
          "refill.endStatus.3": "Erreur L2 dispenser",
          "refill.endStatus.4": "Cible pression dispenser atteinte",
          "refill.endStatus.6": "Erreur station",
          "refill.endStatus.7": "Signal d'interruption",
          "refill.endStatus.8": "SAEJ",
          "refill.endStatus.9": "Signal d'arrêt",
          "refill.endStatus.10": "Arrêt POS",
          "refill.endStatus.11": "Arrêt recharge mode dégradé",
          "refill.endStatus.12": "Cible pression véhicule atteinte",
          "refill.endSubStatus.0": "-",
          "refill.endSubStatus.1": "Initial Pressure out of range",
          "refill.endSubStatus.2": "Volume tank out of range",
          "refill.endSubStatus.3": "Fallback not authorized",
          "refill.endSubStatus.4": "H2 temp above T30 table",
          "refill.endSubStatus.5": "Target Pressure reached with com",
          "refill.endSubStatus.7": "Target Pressure reached without com",
          "refill.endSubStatus.9": "Target Pressure top off reached with com",
          "refill.endSubStatus.10": "Wrong initial calculation",
          "rememberme": "Se souvenir de moi",
          "stationstate-online": "disponible",
          "stationstate-wferror": "erreur liaison PLC",
          "dispenserFuelingStatus-noStatus": "indisponible",
          "dispenserFuelingStatus-readyToFuel": "prêt",
          "dispenserFuelingStatus-preparingToFuel": "démarrage",
          "dispenserFuelingStatus-inProgress": "recharge en cours",
          "dispenserFuelingStatus-stopping": "arrêt en cours",
          "dispenserFuelingStatus-stopped": "arrêté",
          "added.on": "date d'ajout",
          "associated": "associé",
          "stateTechnique": "Statut technique",
          "plc": "Informations PLC",
          "pos.version": "POS Version",
          "wfconnector.version": "WF Connector version",
          "fuelingStatus": "Statut recharge",
          "queue": "File d'attente",
          "nozzlePosition": "Pistolet levé",
          "unlocked": "Dévérouillé",
          "state.open": "Disponible",
          "state.partial": "Partiel",
          "state.busy": "Occupé",
          "state.maintenance": "Maintenance",
          "state.breakdown": "En panne",
          "state.closed": "Fermé",
          "state.empty": "Vide",
          "state.unknown": "inconnu",
          "state.offline": "hors ligne",
          "publicState": "Etat public",
          "lastping": "Dernier ping",
          "current.refillSession": "Utilisateur mobile",
          "current.refill": "Recharge en cours",
          "progress": "Progression",
          "filter.refill.date":"Date des recharges",
          "customer.status.closed": "Fermé",
          "customer.status.created": "Créé",
          "customer.status.ready": "Prêt",
          "customer.status.locked": "Vérouillé",
          "status": "Etat",
          "running": "en cours",
          "not.started": "non démarré",
          "success": "succes",
          "error": "erreur",
          "nodata.todisplay": "aucune donnée à afficher",
          "filter.daily": "Jour",
          "filter.weekly": "Semaine",
          "filter.monthly": "Mois",
          "filter.yearly": "Année",
          "filter.refill.pos": "POS",
          "periode.from": "du",
          "periode.to": "au",
          "pos.nexopus.app": "Nexopus",
          "pos.tokheim": "Tokheim",
          "userRole": "Role",
          "ADMIN": "Administrateur",
          "MANAGER": "Manager",
          "VIEWER": "Consultant",
          "availability": "Disponibilité",
          "station.availability.event.serveronline": "Redémarrage serveur",
          "station.availability.event.serveroffline": "Arrêt serveur",
          "station.availability.event.stationonline": "Station en ligne",
          "station.availability.event.stationoffline": "Station hors ligne",
          "station.availability.event.wfconnectoronline": "WF en ligne",
          "station.availability.event.wfconnectoroffline": "WF hors ligne",
          "station.availability.event.stationstate": "Changement état station",
          "livequantity": "Quantité temps réelle",
          "supportphone": "N° Téléphone support"
        }
      }
    },
    fallbackLng: 'en',
 
    ns: ['translations'],
    defaultNS: 'translations',
 
    keySeparator: false,
 
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
 
    react: {
      wait: true
    }
  });
 
export default i18n;
