import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { data } from './common.reducer';

const rootReducer = combineReducers({
  authentication,
  data
});

export default rootReducer;