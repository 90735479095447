
import React from 'react';
import { connect } from 'react-redux';
import EnhancedTable from '../../_components/EnhancedTable';

import { getRights } from '../../_helpers';

import { commonActions } from '../../_actions';

class MainList extends React.Component {

    constructor(props) {
        super(props)
        this.scope = this.props.scope;
        this.model = this.props.model;
        this.rights = getRights(this.props.user, this.props.context, this.model.name);
    }

    componentDidMount() {
        this.props.dispatch(commonActions.getAll(this.scope, this.model));
    }

    handleAddAction = (e) => {
        this.props.history.push('/edit/' + this.model.name);
    }

    handleEditAction = (item) => {
        this.props.history.push('/edit/' + this.model.name + '/' + item.id);
    }

    handleReadAction = (item) => {
        this.props.history.push('/read/' + this.model.name + '/' + item.id);
    }

    handleDeleteAction = (item) => {
        this.props.dispatch(commonActions.deleteItem(this.scope, item, this.model));
    }

    handleExportAction = () => {
        this.props.dispatch(commonActions.exportAll(this.scope, this.model, null, this.filter));
    }

    render() {
        let { data } = this.props;
        if (data && this.scope) {
            const scopedData = data[this.scope];
            data = scopedData ? scopedData : data;
        }
        const list = this.model.list;
        const items = data.items;

        const columns = list.fields.map(field => { return {id: field, numeric: false, disablePadding: true, label: field }});

        return (
            <div>
                {
                    items &&
                    <EnhancedTable
                        scope={this.scope}
                        model={this.model}
                        columns={columns}
                        rows={items}
                        tableTitle={list.title || this.model.name}
                        addAction={this.rights.create ? this.handleAddAction : null}
                        readAction={this.rights.read ? this.handleReadAction : null}
                        editAction={this.rights.update ? this.handleEditAction : null}
                        deleteAction={this.rights.delete ? this.handleDeleteAction: null}
                        exportAction={this.rights.export ? this.handleExportAction: null}
                        withPagination={true}
                    />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { data, authentication } = state;
    const { user, context } = authentication;
    return {
        data,
        user,
        context
    };
}

const connectedMainListPage = connect(mapStateToProps)(MainList);
export { connectedMainListPage as MainList };