import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate, Trans } from 'react-i18next';
import InboxIcon from '@material-ui/icons/MoveToInbox';

import { MainList } from '../_pages/common/MainList';
import { ItemEditPage } from '../_pages/common/EditItem';
import { ItemReadPage } from '../_pages/common/ReadItem';

import { ResponsiveDrawer }  from './GlobalLayout';

import { Profil, ProfilU2F, ProfilU2FEdition, ProfilU2FCreation, ProfilTOTP, ProfilTOTPCreation, ProfilTOTPEdition} from '../_pages/profil';

import { Objects, ObjectsMap } from '../_config';

class HomePage extends React.Component {
    componentDidMount() {
    }

    render() {
        const { t, user } = this.props;

        const menu =  Objects.filter(obj => obj.menu).map( (model, index) => {return {key: model.name, url: '/list/' + model.name, name: t(model.name), icon: (<InboxIcon/>), model: model};});

        const routes = menu.map( (menuItem, index) => {
            return (<Route key={"route_menu_" + index } exact path={menuItem.url} render={routeProps => <MainList scope="main" {...routeProps} model={menuItem.model}/>} />);
        });

        routes.push((<Route key="profil" exact path="/profil" render={routeProps => <Profil {...routeProps} />} />));
        routes.push((<Route key="profilU2f" exact path="/profil/u2f" render={routeProps => <ProfilU2F {...routeProps} model={ObjectsMap.userU2F}/>} />));
        routes.push((<Route key="profilU2fedit" exact path="/profil/u2f/edit/:itemId" render={routeProps => <ProfilU2FEdition {...routeProps} model={ObjectsMap.userU2F}/>} />));
        routes.push((<Route key="profilU2fcreate" exact path="/profil/u2f/create" render={routeProps => <ProfilU2FCreation {...routeProps} model={ObjectsMap.userU2F}/>} />));

        routes.push((<Route key="profilTOTP" exact path="/profil/totp" render={routeProps => <ProfilTOTP {...routeProps} model={ObjectsMap.userTOTP}/>} />));
        routes.push((<Route key="profilTOTPedit" exact path="/profil/totp/edit/:itemId" render={routeProps => <ProfilTOTPEdition {...routeProps} model={ObjectsMap.userTOTP}/>} />));
        routes.push((<Route key="profilTOTPcreate" exact path="/profil/totp/create" render={routeProps => <ProfilTOTPCreation {...routeProps} model={ObjectsMap.userTOTP}/>} />));

        const routesEdition = Objects.map( (model, index) => {
            return (
                <div  key={"route_edition_" + index }>
                <Route key={"route_edition_create_" + index } exact path={'/edit/' + model.name} render={routeProps => <ItemEditPage  scope="main" {...routeProps} model={model} urlList={'/list/' + model.name}/>} />
                <Route key={"route_edition_update_" + index } exact path={'/edit/' + model.name + '/:itemId'} render={routeProps => <ItemEditPage  scope="main" {...routeProps} model={model} urlList={'/list/' + model.name}/>} />
                <Route key={"route_read_" + index } exact path={'/read/' + model.name + '/:itemId'} render={routeProps => <ItemReadPage  scope="main" {...routeProps} model={model} urlList={'/list/' + model.name}/>} />
                </div>
            );
        });

        return (
            <ResponsiveDrawer user={user} menu={menu}>
                {routes}
                {routesEdition}
            </ResponsiveDrawer>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedHomePage = connect(mapStateToProps)(translate('translations')(HomePage));
export { connectedHomePage as HomePage };