import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import PageviewIcon from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { translate } from 'react-i18next';

import { Objects, ObjectsMap } from '../_config';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, columns, hasColumnActions, showSelectColumn, t } = this.props;

    return (
      <TableHead>
        <TableRow>
          {showSelectColumn &&
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>}
          {columns.map(row => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {t(row.label)}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
          {hasColumnActions && <TableCell>
            Actions
          </TableCell>}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  hasColumnActions: PropTypes.bool.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});


let EnhancedTableToolbar = props => {
  const { tableTitle, rowCount, numSelected, classes, addAction, exportAction } = props;
  const showDelete = false;

  const addButton = addAction ? 
  (<Tooltip title="Add">
    <IconButton aria-label="Add" onClick={addAction}>
      <AddIcon />
    </IconButton>
  </Tooltip>) : (null);

  const filterButton = (<Tooltip title="Filter list">
  <IconButton aria-label="Filter list">
    <FilterListIcon />
  </IconButton>
</Tooltip>);

const exportButton = exportAction ? (<Tooltip title="Export">
  <IconButton aria-label="Export" onClick={exportAction} disabled={rowCount < 1 ? true : false}>
    <VerticalAlignBottomIcon />
  </IconButton>
</Tooltip>) : (null);

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} Véhicules associés
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {tableTitle}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? showDelete ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (null) : 
          (addButton
        )}
        {exportButton}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  tableTitle: PropTypes.string.isRequired,
  buttons: PropTypes.array
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  
  state = {
    order: 'asc',
    orderBy: 'email',
    selected: this.props.selectedItems ? this.props.selectedItems : [],
    data: this.props.rows,
    page: 0,
    rowsPerPage: 10,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    let newSelected;
    if (event.target.checked) {
      newSelected = this.state.data.map(n => n.id);
    } else {
      newSelected = [];
    } 
    if (this.props.handleSelect) {
      this.props.handleSelect(newSelected);
    }
    this.setState({ selected: newSelected });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    if (this.props.handleSelect) {
        this.props.handleSelect(newSelected);
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };


  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  renderCellAction(hasColumnActions, line, readAction, editAction, deleteAction) {

    const cellAction = hasColumnActions ? (<TableCell>
      {readAction &&
        <Tooltip title="Read">
        <IconButton aria-label="Read" onClick={() => readAction(line)}>
          <PageviewIcon />
        </IconButton>
        </Tooltip>
      }
      {editAction &&
        <Tooltip title="Edit">
        <IconButton aria-label="Edit" onClick={() => editAction(line)}>
          <EditIcon />
        </IconButton>
        </Tooltip>
      }
      {deleteAction &&
        <Tooltip title="Delete">
        <IconButton aria-label="Delete" onClick={() => deleteAction(line)}>
          <DeleteIcon />
        </IconButton>
        </Tooltip>
      }
    </TableCell>
    ) : (null);

    return cellAction;
  }

  renderCellSelect(item, isSelected) {
    if (this.props.handleSelect) {
      return (<TableCell padding="checkbox">
      <Checkbox checked={isSelected} onClick={event => this.handleClick(event, item.id)} />
    </TableCell>);
    } else {
      return (null);
    }
  }

  render() {
    const { t, model, tableTitle, rows, columns, classes, withPagination, addAction, readAction, editAction, deleteAction, exportAction } = this.props;

    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const hasColumnActions = (readAction || editAction || deleteAction) ? true : false;
    const showSelectColumn = this.props.handleSelect ? true : false;

    function deepFind(obj, path) {
      var paths = path.split('.')
        , current = obj
        , i;
    
      for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] == undefined) {
          return undefined;
        } else {
          current = current[paths[i]];
        }
      }
      return current;
    }



    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar rowCount={rows.length} numSelected={selected.length} tableTitle={tableTitle} addAction={addAction} exportAction={exportAction}/>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={rows.length}
              columns={columns}
              hasColumnActions={hasColumnActions}
              showSelectColumn={showSelectColumn}
              t={t}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      {showSelectColumn && this.renderCellSelect(n, isSelected)}
                      {columns.map(col => {
                        const field = model.fields[col.id];
                        let value;
                        let objValue;
                        if (field && field.computed) {
                          objValue = field.computed(n, t);
                        } else {
                          objValue = n[col.id];
                        }
                        if (field && field.object) {
                          value = objValue ? ObjectsMap[field.object].toString(objValue) : '';
                        } else if (field && field.type === 'boolean') {
                          value = t((objValue === true || objValue === 1 ? 'yes':'no'));
                        } else if (field && field._toString) {
                          console.log('has to string ', field)
                          value = field._toString(objValue);
                        } else {
                          value = objValue;
                        }
                        return (<TableCell key={'cell_' + col.id} numeric={col.numeric} scope="row">{value}</TableCell>);
                      })}
                      {this.renderCellAction(hasColumnActions, n, readAction, editAction, deleteAction)}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={columns.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {withPagination && <TablePagination
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />}
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  tableTitle: PropTypes.string.isRequired,
  withPagination: PropTypes.bool,
  addAction: PropTypes.func,
  readAction: PropTypes.func,
  editAction: PropTypes.func,
  deleteAction: PropTypes.func
};

export default translate('translations')(withStyles(styles)(EnhancedTable));