import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '../../../_components/edition/DatePicker';

import { ChoiceList } from '../ChoiceList';
import { commonActions } from '../../../_actions';

import { ObjectsMap } from '../../../_config';
import { objectsHelper } from '../../../_helpers';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem',
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
});

class EditObject extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {

        this.props.fields.filter( field => (field.type === 'choice' || field.type === 'choicelist') && field.sourcetype === 'object').forEach( field => {
            const fieldDef = this.props.model.fields[field.name];
            let filters = null;
            if (fieldDef.filter) {
                filters = {};
                fieldDef.filter.forEach( filter => {
                    filters[filter.field] = objectsHelper.getFieldValue(filter.path, this.props.object, this.props.model);
                });
            }
            this.props.dispatch(commonActions.getAll(this.props.scope, ObjectsMap[field.source], field.name, filters));
        });
    }

      getData() {
        let { scope, data } = this.props;

        if (data && scope) {
            const scopedData = data[scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }

    getItem() {
        const data = this.getData();
        return data.item;
    }

    getSource(fieldName) {
        const data = this.getData();
        const source = data && data.sources && data.sources[fieldName];

        return source;
    }

    makelLabelField = (element, fieldConfig) => {
        let label = fieldConfig.displayName ? this.props.t(fieldConfig.displayName) : this.props.t(element.name);
        if (fieldConfig.required) {
            label += ' *';
        }
        return label;
    }

    makeInputFields = (fields, object, classes, path) => {

        const { t } = this.props;

        const inputFields = [];
        if (object) {
            fields.forEach((element, index) => {
                if (!element.hidden) {

                    const globalFieldConfig = this.props.model ? this.props.model.fields[element.name] : {};

                    console.log('render field '+index, element);
                    if (element.type === 'structure') {
                        let structValue = object ? object[element.name] : {};
                        structValue = structValue ? structValue : {};
                        inputFields.push(
                            (<div key={"form_struct_" +index}>

                                    <Typography>{t(element.name)}</Typography>
                                    <ConnectedEditObject
                                        classes={classes}
                                        fields={element.fields}
                                        object={structValue}
                                        handleChange={ object => {
                                            this.handleChangeSubObject(element.name, object);
                                        }}
                                    />
                                    <div>
                                    
                                    </div>
                                </div>
                            )
                        );

                    } else if (element.type === 'choice') {
                        let options;
                        let source;
                        let value;
                        let multiple = element.multiple ? true : false;
						let config = ObjectsMap[this.props.model.fields[element.name].object];
						
                        if (element.sourcetype === 'object') {
                            source = this.getSource(element.name);
                            console.log('field type choice object', element, source);
                            
                            if (multiple) {
                                value = object[element.name] ? object[element.name].map( el => el.id ) : [];
                            } else {
                                value = object[element.name] ? object[element.name].id : '';
                            }

                            options = (source && source.map( (value, index) => {
								var finalName = (config && config.toString) ? config.toString(value, t) : t(value.name);
                                return <MenuItem key={'option_' + element.name + '_' + index} value={value.id}>{finalName}</MenuItem>;
                            }));
                            console.log(source, options);
                        } else if (element.sourcetype === 'value' && element.source) {
                            source = element.source;
                            value = (object[element.name] !== undefined && object[element.name] !== null) ? object[element.name] : multiple ? [] : '';
                            options = (Object.keys(element.source).map( (sourceKey, index) => {
                                const sourceValue = element.source[sourceKey];
                                return <MenuItem key={'option_' + element.name + '_' + index} value={sourceKey}>{t(sourceValue)}</MenuItem>;
                            }));
                        } else {
                            source = [];
                            value = object[element.name];
                            options = (null);
                        }
                        
                        //onChange={this.handleChange(element.name)}
                        
                        inputFields.push(
                            (<FormControl key={"form_input_" +index} className={classes.formControl}>
                                    <InputLabel htmlFor="component-simple">{element.name}</InputLabel>
                            <Select
                                value={value}
                                multiple={multiple}
                                onChange={ e => {
                                    this.handleChangeFromChoice(element.name, multiple, source, element.sourcetype, e);
                                }}
                                inputProps={{
                                    name: element.name,
                                    id: element.name + '-choice',
                                }}
                            >
                                <MenuItem value="">
                                <em>{t('none')}</em>
                                </MenuItem>
                                {options}
                            </Select>
                            </FormControl>
                            )
                        );
                    } else if (element.type === 'choicelist') {

                        let source = this.getSource(element.name);//this.props.data && this.props.data.sources && this.props.data.sources[element.name] ? this.props.data.sources[element.name] : null;
                        const selectedItems = object[element.name] ? object[element.name] : null; //.map( obj => obj.id)
                        
                        inputFields.push(
                            (<div key={"form_choicelist_" +index}>
                            <ChoiceList
                                inFieldName={element.name}
                                model={ObjectsMap[this.props.model.fields[element.name].object]}
                                items={source}
                                selectedItems={selectedItems}
                                handleSelect={selectedItems => {this.handleChangeFromSelect(element.name, selectedItems)}}
                                canCreate={element.canCreate}
                                embedded={element.embedded}
                            />
                            </div>)
                        );
                    } else if (element.type === 'ownlist') {
                        const items = object[element.name];
                        //if (items) {
                            const config = ObjectsMap[this.props.model.fields[element.name].object];
                            
                            const itemsFields = items ? items.map( (item, index) => <ConnectedEditObject
                                model={config}
                                key={element.name + '_' + index}
                                classes={classes}
                                fields={item.id ? config['edition'].fields : config['creation'].fields}
                                object={item}
                                handleChange={ object => {
                                    this.handleChangeSubObject(element.name, object, index);
                                }}
                            />) : null;
                            inputFields.push(
                            <div key={"form_ownlist_" +index}>
                                <Typography>{t(element.name)}</Typography>
                                <Button onClick={ e => {this.handleInsertNewObject(element.name)}}>Add</Button>
                                {itemsFields}
                            </div>
                            );
                        //}
                    } else if (element.type === 'switch' || globalFieldConfig.type === 'boolean') {
                        inputFields.push(
                            <FormControl className={classes.formControl}>
                                <div className="form-input-label">{this.makelLabelField(element, globalFieldConfig)}</div>
                        <FormControlLabel
                        key={"form_input_" +index}
                        control={
                            <Switch
                            checked={object[element.name]}
                            onChange={this.handleChange(element.name, !(object[element.name] === true))}
                            value={object[element.name]}
                            />
                        }
                        label={t(object[element.name] === true || object[element.name] === 1 ? 'yes' : 'no')}
                        /></FormControl>);
                    } else if (element.type == 'date') {
						
						inputFields.push(
							(
								<FormControl key={"form_input_" +index} className={classes.formControl}>
									<DatePicker 
											id={"field_"+index}
											label={t(element.name)} 
											value={object[element.name]}
											onChange={this.handleChange(element.name)}
									/>
								</FormControl>
							)
						);
						
                    } else {
                        inputFields.push(
                            (<FormControl key={"form_input_" +index} className={classes.formControl}>
                                    <InputLabel htmlFor="component-simple">{t(element.name)}</InputLabel>
                                    <Input id="component-simple" disabled={element.readonly ? true : false} value={object[element.name]} onChange={this.handleChange(element.name)}/>
                                </FormControl>
                            )
                        );

                    }

                }
            });
        }
        return inputFields;

      }

      handleChange = (field, value) => e => {
        console.log('EditObject handle change ', field, value, e.target.value);
        this.updateObject(field, (value !== undefined) ? value : e.target.value);
      }

      handleChangeFromChoice = (field, multiple, source, sourcetype, e) => {

        console.log('EditObject handle change ', field, source, sourcetype, e.target.value);

        if (Array.isArray(e.target.value)) {
            if (sourcetype === 'object') {
                const value = source.filter( element => e.target.value.indexOf(element.id) > -1 );
                this.updateObject(field, value);
            }  else {
                const value = [];
                e.target.value.forEach( v=> {
                    if (source[v]) {
                        value.push(v);
                    }
                })
                this.updateObject(field, value);
            }
            
        }  else {
            const value = sourcetype === 'object' ? source.find( element => element.id === e.target.value) : source[e.target.value] ? e.target.value : null;
            this.updateObject(field, value);
        }

        
      }

      handleChangeFromSelect = (field, selectedItems) => {
        console.log('EditObject handle change select', field, selectedItems);
        this.updateObject(field, selectedItems);
    }

      handleChangeSubObject = (field, value, index) => {
        console.log('EditObject handle change from sub ', field, value, index);        
        this.updateObject(field, value, index);
    }

    handleInsertNewObject = (field) => {
        let array = this.props.object[field];
        if (!array) {
            array = [];
            this.props.object[field] = array;
        }
        array.push({});
        this.notifyChange();
    }

    updateObject = (field, value, index) => {
        if (index || index === 0) {
            this.props.object[field][index] = value;
        } else {
            this.props.object[field] = value;
        }
        this.notifyChange();
    }

    notifyChange() {
        if (this.props.handleChange) {
            this.props.handleChange(this.props.object);
        }
    }

    render() {
        const { classes, object, fields } = this.props;

        const formFields = this.makeInputFields(fields, object, classes);

        return formFields;
    }
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    
    return {
        itemId,
        data
    };
}

const ConnectedEditObject = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditObject)));
export { ConnectedEditObject as EditObject };