import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { history } from '../_helpers';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

const mytheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  }/*,
  palette: {
    primary: {main: '#205081'},
    secondary: {main: '#00a65a'},
    error: red,
  },*/
});

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: '100%',//`calc(100% - ${drawerWidth}px)`,
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  grow: {
    flexGrow: 1,
  },
  breadcrumbLi: {
    float: 'left'
  }
});

class ResponsiveDrawer extends React.Component {
  state = {
    mobileOpen: false,
    anchorEl: null,
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleProfil = () => {
    this.setState({ anchorEl: null });
    history.push('/profil');
  };

  handleLogout = () => {
    history.push('/login');
  };

  render() {
    const { t, user, menu, classes, theme } = this.props;
    
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const withBreadcrumb = true;
    
    const Breadcrumbs = (props) => (
        withBreadcrumb && this.props.main ?
        (<div className="breadcrumbs">
            <ul className='container'>
                
            </ul>
        </div>) : (null)
    )

    const BreadcrumbsItem = ({ match }) => (
        <span>
            <li className={match.isExact ? 'breadcrumbLi breadcrumb-active' : 'breadcrumbLi'}>
                <Link to={match.url || ''}>
                    {match.url}
                </Link>
            </li>
            <Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
        </span>
    )

      const menuContent = menu.map( (item, index) => {
        return (
          <Link key={'mainmenulink_' + index} to={item.url}>
            <ListItem button to={item.key}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          </Link>
        );
      } );

    const drawer = (
      <div>
        <div className={classes.toolbar} >
            
        </div>
        <Divider />
        <List>
            {menuContent}
        </List>
      </div>
    );

    return (
        <MuiThemeProvider theme={mytheme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/"><HomeIcon/></Link>
            <Breadcrumbs/>
            <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
              
            </Typography>
            <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                    <Typography variant="subtitle2" color="inherit" noWrap>
                    {user && user.user && user.user.email}
              </Typography>
                
                  <AccountCircle />
                </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleProfil}>{t('action.profil')}</MenuItem>
              <MenuItem onClick={this.handleLogout}>{t('action.logout')}</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swap with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
      </MuiThemeProvider>
    );
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { data } = state;
  const { main } = data;
  return {
    main
  };
}

const connectedResponsiveDrawer = connect(mapStateToProps)(withStyles(styles, { withTheme: true })(translate('translations')(ResponsiveDrawer)));
export { connectedResponsiveDrawer as ResponsiveDrawer };

//export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);