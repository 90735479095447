export const commonConstants = {

    READ_REQUEST: 'COMMON_READ_REQUEST',
    READ_SUCCESS: 'COMMON_READ_SUCCESS',
    READ_FAILURE: 'COMMON_READ_FAILURE',

    EDIT_REQUEST: 'COMMON_EDIT_REQUEST',
    EDIT_SUCCESS: 'COMMON_EDIT_SUCCESS',
    EDIT_FAILURE: 'COMMON_EDIT_FAILURE',

    GETALL_REQUEST: 'COMMON_GETALL_REQUEST',
    GETALL_SUCCESS: 'COMMON_GETALL_SUCCESS',
    GETALL_FAILURE: 'COMMON_GETALL_FAILURE',

    CREATE_REQUEST: 'COMMON_CREATE_REQUEST',
    CREATE_SUCCESS: 'COMMON_CREATE_SUCCESS',
    CREATE_FAILURE: 'COMMON_CREATE_FAILURE',

    SAVE_REQUEST: 'COMMON_SAVE_REQUEST',
    SAVE_SUCCESS: 'COMMON_SAVE_SUCCESS',
    SAVE_FAILURE: 'COMMON_SAVE_FAILURE',

    UPDATE_ITEM_EDITION_REQUEST: 'COMMON_UPDATE_EDITION',

    DELETE_REQUEST: 'COMMON_DELETE_REQUEST',
    DELETE_SUCCESS: 'COMMON_DELETE_SUCCESS',
    DELETE_FAILURE: 'COMMON_DELETE_FAILURE',

    GO_CREATE_REQUEST: 'COMMON_GO_CREATE_REQUEST',
};